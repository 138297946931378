#modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 500;
    display: none;

    &.open {
        display: block;
    }

    .modal {
        width: 300px;
        height: 300px;
        position: absolute;
        left: calc(50% - 150px);
        top: calc(50% - 150px);
        background: #FFF;
        border-radius: 8px;
        z-index: 502;
        position: absolute;
        padding: 20px;

        #modal-close {
            text-decoration: none;
            color: $primary-gray;
            position: absolute;
            top: 10px;
            right: 10px;
            background: none;
            border: none;
            text-align: right;
            padding: 0;
            width: 24px;
            text-align: center;
            height: 20px;
            line-height: 20px;
        }

        #modal-label {
            color: #FFF;
            text-align: center;
            display: block;
            height: 30px;
            width: 100%;
            z-index: 5000;
            position: absolute;
            top: -30px;
            left: 0;
            line-height: 30px;
        }

        #modal-header-tabs {
            width: 100%;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            padding: 8px;
            z-index: 500;
            background: #FFF;
            border-radius: 10px;
            padding-left: 20px;

            .show-button {
                background: #666;
                color: white;
                text-align: center;
                padding: 4px 8px;
                margin: 0;
                text-decoration: none;
                font-size: $font-default;
                width: 120px;
                display: inline-block;
                border: none;

                &.active {
                    background: $primary-blue;
                }
            }
        }

        #modal-content {
            height: 100%;
            overflow: hidden;

            #city-select {
                position: absolute;
                top: 36px;
                max-height: calc(100% - 50px);
                overflow: scroll;
                width: calc(100% - 40px);

                &.sheriff {
                    li.police-department {
                        display: none;
                    }
                }

                &.police-department {
                    li.sheriff {
                        display: none;
                    }
                }
            }

            #state-select {
                position: absolute;
                top: 20px;
                max-height: calc(100% - 40px);
                overflow: scroll;
                width: calc(100% - 40px);
                display: none;
            }

            #more-info-content {
                overflow: scroll;
                height: 100%;
            }

            ul li {
                width: 100%;

                a {
                    line-height: 30px;
                    text-decoration: none;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    width: 100%;
                    display: block;
                    color: $primary-gray;

                    &.selected-city,
                    &.active {
                        color: $primary-blue;
                    }
                }

                &:last-child a {
                    border-bottom: none;
                }

                &.section-header {
                    background: #222;
                    color: white;
                    text-align: center;
                    padding: 8px;
                    margin: 12px 0 3px;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }

            em,
            i {
                font-style: italic;
            }

            strong,
            b {
                font-style: normal;
                font-weight: bold;
            }

            a {
                text-decoration: none;
                color: $primary-blue;
            }
        }
    }

    &.large {
        .modal {
            height: auto;
            top: calc(50% - 240px);

            #modal-content {
                height: 100%;
            }
        }
    }

    #overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 501;
        background: rgba(0, 0, 0, 0.9);
        display: block;
    }
}

#main.modal-open, #main.modal-open *, .focus-disabled {
    pointer-events: none;
}
