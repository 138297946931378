@charset "UTF-8";
/* Base Imports */
/* Old Colors */
@font-face {
  font-family: "statefaceregular";
  src: url("../fonts/StateFace-Regular-webfont.eot");
  src: url("../fonts/StateFace-Regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/StateFace-Regular-webfont.woff") format("woff"), url("../fonts/StateFace-Regular-webfont.ttf") format("truetype"), url("../fonts/StateFace-Regular-webfont.svg#statefaceregular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/* CSS RESET */
a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details,
dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins,
kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary,
sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:after, blockquote:before, q:after, q:before {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

html {
  scroll-behavior: smooth;
}

/* COMMON ELEMENTS */
body {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  color: #464648;
}

p {
  line-height: 1.57;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

/* THEME ELEMENTS */
.advocacy-tip {
  font-size: 14px;
  padding: 20px;
  margin-top: 20px;
  background: #FFFFFF;
  border-radius: 10px;
}

.buttons {
  text-align: left;
  margin-top: 18px;
  display: block;
  height: 30px;
  margin-bottom: 10px;
}
.buttons .btn {
  margin: 4px;
  background: #FFF;
  border: 2px solid #5a6f83;
  padding: 4px 12px;
  cursor: pointer;
  text-decoration: none;
  color: #5a6f83;
  border-radius: 6px;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
}
.buttons .btn.active {
  background: #5a6f83;
  color: white;
}
.buttons .btn:first-child {
  margin-left: 0;
}

.current-state {
  text-align: center;
  color: #5a6f83;
  font-weight: 500;
  height: 100px;
  line-height: 100px;
  font-size: 24px;
  text-transform: uppercase;
  font-style: italic;
  font-family: "Barlow Condensed", sans-serif;
}
.current-state svg {
  position: relative;
  top: 5px;
}

.iframe {
  max-width: 100% !important;
}

.grades td {
  position: relative;
  white-space: nowrap;
}
.grades td a.score {
  display: inline-block;
  vertical-align: middle;
}
.grades td .tooltip {
  position: unset;
  top: unset;
  left: unset;
  display: inline-block;
}

.partial-data {
  font-size: 13px;
  line-height: 1.62;
}

.section.footer .state-list-footer {
  padding: 0 !important;
}
.section.footer .state-list-footer .one-fifth {
  width: 19%;
  display: inline-block;
  vertical-align: top;
  margin-top: 16px;
  margin-bottom: 0;
}
.section.footer .state-list-footer .one-fifth a.state-link {
  color: #FFF;
  text-decoration: none;
  font-weight: 300;
  font-family: "Barlow Condensed", sans-serif;
  display: block;
  line-height: 22px;
  font-size: 16px;
  text-align: left;
  letter-spacing: normal;
  text-transform: none;
}
.section.footer .state-list-footer .one-fifth a.state-link.active {
  font-weight: 500;
  text-decoration: underline;
}
.section.footer .state-list-footer .one-fifth a.state-link:hover {
  text-decoration: underline;
}

.selected-location .selected-location-label {
  color: white;
  display: inline-block;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1.5px;
  line-height: 20px;
  opacity: 0.5;
  position: absolute;
  text-decoration: none;
  text-transform: uppercase;
  bottom: 24px;
}
.selected-location a {
  font-family: "Barlow Condensed", sans-serif;
  color: #5a6f83;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 60px;
  text-decoration: none;
  position: relative;
  padding-bottom: 30px;
  top: -12px;
  display: inline-block;
}
.selected-location a::after {
  content: "◢";
  font-size: 18px;
  color: #F19975;
  position: absolute;
  right: -40px;
  top: 12px;
  transform: rotate(45deg);
  animation: bounce_select 1s 3 ease-in-out;
  animation-delay: 3s;
}
.selected-location.long .selected-location-label {
  bottom: 16px;
}
.selected-location.long a {
  top: -8px;
  font-size: 48px;
}
.selected-location.really-long {
  max-width: 320px;
}
.selected-location.really-long .selected-location-label {
  bottom: 16px;
}
.selected-location.really-long a {
  top: -8px;
  font-size: 28px;
}

.source-link-wrapper {
  text-align: right;
  font-style: italic;
  font-size: 13px !important;
  color: #5a6f83;
  font-weight: 600;
  margin-top: 10px !important;
}
.source-link-wrapper a {
  color: #5a6f83;
  font-weight: 600;
  margin-left: 2px;
}
.source-link-wrapper a:hover, .source-link-wrapper a:focus {
  color: black;
  text-decoration: underline;
}

.spending p.note {
  margin-top: 8px;
  text-align: center;
}
.spending .keys {
  margin-top: 14px;
}
.spending p strong {
  font-weight: 600;
  font-size: 12px;
}

.state-symbol {
  font-family: "statefaceregular", Arial, sans-serif;
  color: #F2F4F6;
  background: #626262;
  width: 42px;
  height: 42px;
  display: inline-block;
  line-height: 44px;
  border-radius: 50%;
  text-align: center;
  padding: 0;
  margin: 0;
  margin-right: 5px;
  text-transform: none;
  font-size: 24px;
  font-style: normal;
}

.stats-change {
  position: absolute;
  right: 0;
}

.text-center {
  text-align: center;
}

.text-white {
  color: #FFF;
}
.text-white iframe {
  background: #FFF;
  padding: 8px 16px;
  display: block;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}
.text-white .subtitle {
  color: #FFF;
  padding: 3rem 0 2rem 0;
  display: block;
}

.tile-chart {
  width: 320px;
  height: 80px;
  margin: 0 auto;
  margin-top: 20px;
}
.tile-chart .tile {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: #eeeeee;
  float: left;
  box-shadow: inset 0 0 0 1px #FFF;
}
.tile-chart .tile.color-1 {
  background-color: #b02424;
}
.tile-chart .tile.color-2 {
  background-color: #58595b;
}
.tile-chart .tile.color-3 {
  background-color: #9a9b9f;
}
.tile-chart .tile.color-4 {
  background-color: #d4d9e4;
}

.title {
  font-family: "Barlow Condensed", sans-serif;
}
.title.white {
  color: white;
  display: inline-block;
  font-size: 14px;
  line-height: 14px;
  margin: 0;
  background: #a7cc84;
  clear: both;
  padding: 8px;
  font-weight: 600;
  position: relative;
  top: 10px;
}
.title.white.bad {
  background-color: #f67f85;
  color: #FFF !important;
}

.tooltip {
  cursor: default;
  z-index: 1000;
  font-size: 16px;
  font-family: monospace;
  height: 18px;
  line-height: 16px;
  display: inline-block;
  top: 2px;
  z-index: 50;
  width: 22px;
}
.tooltip .grade-arrow {
  position: relative;
  display: block;
  width: 100%;
  height: 24px;
}
.tooltip .grade-arrow span {
  position: absolute;
  left: 0;
  font-size: 14px;
  font-family: -apple-system, monospace;
  display: block;
  line-height: 8px;
  width: 100%;
  top: 1px;
  text-align: center;
  height: 7px;
  color: black;
}
.tooltip .grade-arrow small {
  position: absolute;
  left: 0;
  font-size: 10px;
  font-family: "Lato", sans-serif;
  text-align: center;
  width: 100%;
  display: block;
  bottom: 0;
  color: black;
}
.tooltip.bad {
  color: #b02424 !important;
}
.tooltip.bad.reverse {
  color: #1f8234 !important;
}
.tooltip.bad .grade-arrow span {
  bottom: 0;
  transform: rotate(-90deg);
}
.tooltip.good {
  color: #1f8234 !important;
}
.tooltip.good.reverse {
  color: #b02424 !important;
}
.tooltip.good .grade-arrow span {
  transform: rotate(90deg);
}
.tooltip::before {
  content: attr(data-tooltip);
  display: none;
  position: absolute;
  top: -4px;
  left: -180px;
  background: black;
  color: white;
  white-space: nowrap;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 11px;
  line-height: 12px;
  font-family: "Lato", sans-serif;
  width: 170px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
}
.tooltip:focus, .tooltip:hover {
  color: black !important;
}
.tooltip:focus::before, .tooltip:hover::before {
  display: block;
}

.wrapper {
  width: 100%;
}

div.number-list {
  padding-left: 80px;
  position: relative;
}

p.number-list {
  position: relative;
}
p.number-list::after {
  content: "";
  position: absolute;
  background: #a7cc84;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  left: -60px;
  display: block;
  top: 0;
  line-height: 40px;
  text-align: center;
  font-weight: 600;
  font-family: "Barlow Condensed", sans-serif;
  color: #FFF;
}
p.number-list.n1::after {
  content: "1";
}
p.number-list.n2::after {
  content: "2";
}
p.number-list.n3::after {
  content: "3";
}
p.number-list.n4::after {
  content: "4";
}
p.number-list.n5::after {
  content: "5";
}
p.number-list.n6::after {
  content: "6";
}
p.number-list.n7::after {
  content: "7";
}
p.number-list.n8::after {
  content: "8";
}
p.number-list.n9::after {
  content: "9";
}
p.number-list.n10::after {
  content: "10";
}
p.number-list.n11::after {
  content: "11";
}
p.number-list.n12::after {
  content: "12";
}
p.number-list.n13::after {
  content: "13";
}
p.number-list.n14::after {
  content: "14";
}
p.number-list.n15::after {
  content: "15";
}

#chart-police-funding {
  margin-top: 20px;
}

#state-selection {
  text-align: center;
  color: #5a6f83;
  font-weight: 500;
  height: 100px;
  line-height: 100px;
  font-size: 24px;
  text-transform: uppercase;
  font-style: normal;
  font-family: "Barlow Condensed", sans-serif;
  text-decoration: none;
  position: relative;
}
#state-selection::after {
  content: "◢";
  font-size: 14px;
  color: #626262;
  position: absolute;
  right: -60px;
  top: -27px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  display: block;
  width: 20px;
  height: 20px;
  font-style: normal;
}

#sticky-top-bar {
  position: sticky;
  top: 0;
  left: 0;
  text-align: center;
  color: white;
  text-transform: uppercase;
  background-color: #a7cc84;
  font-size: 16px;
  padding: 6px;
  width: 100%;
  text-decoration: none;
  display: block;
  z-index: 500;
}

header a:focus::before, footer a:focus::before {
  content: "";
  border: 2px solid #FFF;
}

[role=tablist] {
  overflow: visible;
  background: rgba(170, 184, 197, 0.3);
  height: 40px;
  border-radius: 8px 8px 0 0;
  text-align: center;
  margin-top: 20px;
}

[role=tab] {
  margin: 0;
  height: 40px;
  border-bottom: 4px solid transparent;
  background: transparent;
  border: none;
  width: 33%;
  text-align: center;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 500;
  color: #324354;
  font-size: 20px;
  line-height: 40px;
}

[role=tab]:hover,
[role=tab]:focus,
[role=tab][aria-selected=true] {
  border-bottom: 4px solid rgba(170, 184, 197, 0.75);
}

[role=tab][aria-selected=true] {
  border-bottom: 4px solid #5a6f83;
  outline: 0;
  color: #324354;
}

[role=tab][aria-selected=true]:not(:focus):not(:hover) {
  border-bottom: 4px solid #5a6f83;
}

[role=tab]:hover,
[role=tab]:focus,
[role=tab]:active {
  outline: 0;
  color: black;
}

[role=tab]:hover::before,
[role=tab]:focus::before {
  border-bottom: 4px solid #5a6f83;
}

[role=tabpanel] {
  position: relative;
  z-index: 2;
  padding: 24px;
  border-radius: 0 0 8px 8px;
  background: #f3f4f6;
  margin-bottom: 14px;
  width: 100%;
}
[role=tabpanel] .left,
[role=tabpanel] .right {
  width: 49%;
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
}
[role=tabpanel] .left {
  padding-right: 5%;
}
[role=tabpanel] .right {
  padding-left: 5%;
}
[role=tabpanel] ul,
[role=tabpanel] li,
[role=tabpanel] p,
[role=tabpanel] .number::before,
[role=tabpanel] ul.contacts li {
  font-size: 14px !important;
  line-height: 20px !important;
}

[role=tabpanel]:focus {
  box-shadow: 0 0 0.2em #b7b7b8;
  outline: 0;
}

a:not(.tooltip):focus {
  position: relative;
  outline: none;
}
a:not(.tooltip):focus::before {
  content: "";
  border: 2px solid #5a6f83;
  position: absolute;
  top: -4px;
  width: calc(100% + 8px);
  left: -4px;
  height: calc(100% + 8px);
  border-radius: 4px;
}

.btn:not(.tooltip):focus, .button:not(.tooltip):focus {
  position: relative;
  outline: none;
}
.btn:not(.tooltip):focus::before, .button:not(.tooltip):focus::before {
  content: "";
  border: 2px solid #5a6f83;
  position: absolute;
  top: -8px;
  width: calc(100% + 16px);
  left: -8px;
  height: calc(100% + 16px);
  border-radius: 8px;
}

.section.score a:not(.tooltip):focus::before {
  content: "";
  border: 2px solid #FFFFFF;
  position: absolute;
  top: 12px;
  width: calc(100% + 8px);
  left: 0;
  height: calc(100% - 24px);
  border-radius: 4px;
}

.section.jail .stat-wrapper .progress-bar-wrapper .progress-bar.grouped span {
  background: #454548;
  color: white;
}

#modal-content a:not(.tooltip):focus::before {
  content: "";
  top: 0px;
  width: 100%;
  left: 0px;
  height: 100%;
  border-radius: 4px;
}

#score-location:focus::before {
  content: "";
  border: 2px solid #FFFFFF;
  position: absolute;
  top: -4px;
  width: calc(100% + 66px);
  left: -12px;
  height: calc(100% + 34px);
  border-radius: 8px;
}

#skip-nav {
  position: absolute;
  left: -999px;
  width: 1px;
  height: 1px;
  top: auto;
}
#skip-nav:focus {
  color: black;
  height: auto;
  width: auto;
  position: absolute;
  background: white;
  left: 20px;
  top: 20px;
  clip: unset;
  padding: 8px 12px;
  text-decoration: none;
  border-radius: 4px;
}

#state-selection:focus::before {
  content: "";
  top: -12px;
  width: calc(100% + 50px);
  left: -8px;
  height: calc(100% + 26px);
}

/* Theme Imports */
.header {
  height: 120px;
}
.header .content {
  height: 120px;
}
.header .logo {
  position: relative;
  top: 33px;
  left: 25px;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 300;
  font-size: 28px;
  word-break: break-word;
  width: 150px;
  display: inline-block;
  line-height: 0.96;
}
.header #mobile-toggle {
  display: none;
}
.header #menu {
  float: right;
}
.header #menu > ul > li {
  display: inline-block;
  margin-right: 20px;
}
.header #menu > ul > li:last-child {
  margin-right: 0;
}
.header #menu > ul > li > a {
  color: #FFF;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 11px;
  letter-spacing: 1px;
  transition: color 0.25s;
}
.header #menu > ul > li > a:hover, .header #menu > ul > li > a.active {
  color: #97bcff;
}
.header #menu > ul > li.menu-divider {
  width: 1px;
  border-right: 1px solid #b3b3b3;
  line-height: 18px;
}
.header #menu #search-icon.fa-circle-o-notch {
  animation: spin 15s linear infinite;
  line-height: 0;
  color: #464648;
}
.header .search-wrapper {
  position: relative;
}
.header .search-form {
  display: none;
  font-size: 16px;
  padding: 0 15px;
  min-width: 300px;
  width: 100%;
  margin: 0;
  background: #FFF;
  border-radius: 4px;
  position: relative;
  height: 40px;
  top: 20px;
  line-height: 16px;
}
.header .search-form .form-control {
  color: #333;
  font-weight: 700;
}
.header .search-form ::-webkit-input-placeholder {
  color: #666;
  font-weight: 700;
}
.header .search-form ::-moz-placeholder {
  color: #666;
  font-weight: 700;
}
.header .search-form :-ms-input-placeholder {
  color: #666;
  font-weight: 700;
}
.header .search-form :-moz-placeholder {
  color: #666;
  font-weight: 700;
}
.header .search-form input {
  text-transform: uppercase;
  width: calc(100% - 60px);
  z-index: 100;
  position: absolute;
  outline: none;
  border: none;
  line-height: 38px;
  top: 0;
  font-size: 16px;
  padding: 0;
  margin: 0;
  line-height: 42px;
  background: transparent;
}
.header .search-form input.no-results {
  text-decoration: line-through;
  color: #dc4646;
}
.header .search-form.active {
  display: inline-block;
}
.header .toggle-search.active, .header .toggle-mobile-search.active {
  display: none;
}
.header .search-button {
  width: 40px;
  height: 40px;
  line-height: 0;
  position: absolute;
  top: 0;
  right: 6px;
  overflow: hidden;
  background: #FFF;
  color: #333;
  border: none;
  outline: none;
  z-index: 200;
  text-align: right;
  font-size: 15px;
}
.header #search-results-container, .header #mobile-search-results-container {
  position: absolute;
  display: none;
  background: white;
  left: 0;
  border-radius: 4px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  top: 0;
  z-index: 0;
  width: 100%;
  padding-top: 36px;
}
.header #search-results-container > ul > li, .header #mobile-search-results-container > ul > li {
  line-height: unset;
  margin-top: 8px;
  vertical-align: middle;
  height: 32px;
  display: block;
}
.header #search-results-container ul, .header #mobile-search-results-container ul {
  padding: 0 8px 8px 8px;
}
.header #search-results-container li.no-results, .header #mobile-search-results-container li.no-results {
  text-transform: uppercase;
  text-align: center;
  color: #dc4646;
  font-weight: 600;
  font-size: 12px;
}
.header #search-results-container .search-result, .header #mobile-search-results-container .search-result {
  vertical-align: middle;
  text-decoration: none;
  display: block;
  height: 32px;
  display: block;
  position: relative;
}
.header #search-results-container .search-result::after, .header #mobile-search-results-container .search-result::after {
  content: "◢";
  font-size: 12px;
  color: #FFF;
  position: absolute;
  right: 16px;
  top: 8px;
  transform: rotate(-45deg);
  transition: all 0.15s;
}
.header #search-results-container .search-result .score, .header #mobile-search-results-container .search-result .score {
  height: 32px;
  width: 32px;
  display: inline-block;
  position: relative;
  top: 0;
  color: white;
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Barlow Condensed", sans-serif;
  vertical-align: middle;
  border-radius: 4px;
}
.header #search-results-container .search-result .score.grade-a, .header #search-results-container .search-result .score.grade-a-minus, .header #search-results-container .search-result .score.grade-a-plus, .header #mobile-search-results-container .search-result .score.grade-a, .header #mobile-search-results-container .search-result .score.grade-a-minus, .header #mobile-search-results-container .search-result .score.grade-a-plus {
  background-color: #1f8234;
}
.header #search-results-container .search-result .score.grade-b, .header #search-results-container .search-result .score.grade-b-minus, .header #search-results-container .search-result .score.grade-b-plus, .header #mobile-search-results-container .search-result .score.grade-b, .header #mobile-search-results-container .search-result .score.grade-b-minus, .header #mobile-search-results-container .search-result .score.grade-b-plus {
  background-color: #537c15;
}
.header #search-results-container .search-result .score.grade-c, .header #search-results-container .search-result .score.grade-c-minus, .header #search-results-container .search-result .score.grade-c-plus, .header #mobile-search-results-container .search-result .score.grade-c, .header #mobile-search-results-container .search-result .score.grade-c-minus, .header #mobile-search-results-container .search-result .score.grade-c-plus {
  background-color: #717b02;
}
.header #search-results-container .search-result .score.grade-d, .header #search-results-container .search-result .score.grade-d-minus, .header #search-results-container .search-result .score.grade-d-plus, .header #mobile-search-results-container .search-result .score.grade-d, .header #mobile-search-results-container .search-result .score.grade-d-minus, .header #mobile-search-results-container .search-result .score.grade-d-plus {
  background-color: #9e6711;
}
.header #search-results-container .search-result .score.grade-f, .header #mobile-search-results-container .search-result .score.grade-f {
  background-color: #c14923;
}
.header #search-results-container .search-result .score.grade-f-minus, .header #mobile-search-results-container .search-result .score.grade-f-minus {
  background-color: #b02424;
}
.header #search-results-container .search-result .score.grade-incomplete, .header #mobile-search-results-container .search-result .score.grade-incomplete {
  background-color: #5a6f83;
  font-size: 30px;
  font-weight: 400;
  line-height: 42px;
}
.header #search-results-container .search-result .details, .header #mobile-search-results-container .search-result .details {
  display: inline-block;
  width: calc(100% - 36px);
  padding-left: 4px;
  font-family: "Barlow Condensed", sans-serif;
  text-transform: uppercase;
  top: 0;
  position: relative;
  vertical-align: middle;
  height: 32px;
}
.header #search-results-container .search-result .details .label, .header #mobile-search-results-container .search-result .details .label {
  font-weight: 500;
  color: #222;
  line-height: 14px;
  text-overflow: ellipsis;
  width: 90%;
  display: block;
  overflow: hidden;
}
.header #search-results-container .search-result .details .label span, .header #mobile-search-results-container .search-result .details .label span {
  color: #000;
  font-weight: 600;
  border-bottom: 1px solid #999;
}
.header #search-results-container .search-result .details .type, .header #mobile-search-results-container .search-result .details .type {
  color: #666;
  font-size: 10px;
  line-height: 10px;
  margin-top: 3px;
  line-height: 14px;
}
.header #search-results-container .search-result:hover::after, .header #search-results-container .search-result:active::after, .header #search-results-container .search-result:focus::after, .header #mobile-search-results-container .search-result:hover::after, .header #mobile-search-results-container .search-result:active::after, .header #mobile-search-results-container .search-result:focus::after {
  content: "◢";
  color: #464648;
  right: 8px;
}
.header .mobile-search-wrapper {
  position: absolute;
  top: 0;
  right: 60px;
  width: 50px;
  height: 60px;
}
.header .mobile-search-wrapper .toggle-mobile-search {
  position: absolute;
  right: 0;
  color: white;
  font-size: 20px;
  top: 18px;
}
.header .mobile-search-wrapper #mobile-search-icon.fa-circle-o-notch {
  animation: spin 15s linear infinite;
  line-height: 0;
  color: #464648;
}

@media screen and (max-width: 940px) {
  .header .search-form {
    top: 0;
    position: absolute;
    min-width: unset;
    display: block;
  }
  .header #menu li.menu-divider {
    display: none;
  }

  #main .mobile-search-wrapper {
    width: calc(100% - 250px);
    animation: animate_mobile_search 1s ease-in-out 2s;
    animation-fill-mode: forwards;
  }
  #main #mobile-search-form {
    top: 8px;
  }
  #main #mobile-search-results-container {
    z-index: 99;
  }
}
#state-map-layer,
#usa-map-layer {
  width: 100%;
  height: 340px;
  margin: 0;
  display: block;
  position: relative;
}

#map-loading {
  background: rgba(255, 255, 255, 0.85);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  text-align: center;
  padding-top: 150px;
  font-size: 24px;
  color: #464648;
}

text.highcharts-credits {
  display: none;
}

svg.highcharts-root {
  overflow: visible;
}
svg.highcharts-root path.highcharts-point {
  stroke: #444;
  stroke-width: 0.25px;
}

.highcharts-container {
  overflow: visible !important;
}

.highcharts-label.highcharts-data-label {
  display: none !important;
}

.highcharts-mappoint-series.highcharts-series-8 image {
  filter: url(#drop-shadow);
}

.highcharts-map-series path {
  fill: #FFF;
  transition: fill 0.25s;
  /* Grade Incomplete */
  /* Grade F Minus */
  /* Grade F */
  /* Grade D */
  /* Grade C */
  /* Grade B */
  /* Grade A */
}
.highcharts-map-series path.highcharts-color-1, .highcharts-map-series path.highcharts-color-2, .highcharts-map-series path.highcharts-color-3, .highcharts-map-series path.highcharts-color-4, .highcharts-map-series path.highcharts-color-5, .highcharts-map-series path.highcharts-color-6, .highcharts-map-series path.highcharts-color-7 {
  fill: #dddee0;
  stroke: transparent;
}
.highcharts-map-series path.highcharts-color-1:hover {
  fill: #5a6f83 !important;
  stroke: #5a6f83 !important;
}
.highcharts-map-series path.highcharts-color-1.active, .highcharts-map-series path.highcharts-color-1.active:hover {
  fill: #5a6f83 !important;
  stroke: #5a6f83 !important;
}
.highcharts-map-series path.highcharts-color-2:hover {
  fill: #b02424 !important;
  stroke: #b02424 !important;
}
.highcharts-map-series path.highcharts-color-2.active, .highcharts-map-series path.highcharts-color-2.active:hover {
  fill: #b02424 !important;
  stroke: #b02424 !important;
}
.highcharts-map-series path.highcharts-color-3:hover {
  fill: #c14923 !important;
  stroke: #c14923 !important;
}
.highcharts-map-series path.highcharts-color-3.active, .highcharts-map-series path.highcharts-color-3.active:hover {
  fill: #c14923 !important;
  stroke: #c14923 !important;
}
.highcharts-map-series path.highcharts-color-4:hover {
  fill: #9e6711 !important;
  stroke: #9e6711 !important;
}
.highcharts-map-series path.highcharts-color-4.active, .highcharts-map-series path.highcharts-color-4.active:hover {
  fill: #9e6711 !important;
  stroke: #9e6711 !important;
}
.highcharts-map-series path.highcharts-color-5:hover {
  fill: #717b02 !important;
  stroke: #717b02 !important;
}
.highcharts-map-series path.highcharts-color-5.active, .highcharts-map-series path.highcharts-color-5.active:hover {
  fill: #717b02 !important;
  stroke: #717b02 !important;
}
.highcharts-map-series path.highcharts-color-6:hover {
  fill: #537c15 !important;
  stroke: #537c15 !important;
}
.highcharts-map-series path.highcharts-color-6.active, .highcharts-map-series path.highcharts-color-6.active:hover {
  fill: #537c15 !important;
  stroke: #537c15 !important;
}
.highcharts-map-series path.highcharts-color-7:hover {
  fill: #1f8234 !important;
  stroke: #1f8234 !important;
}
.highcharts-map-series path.highcharts-color-7.active, .highcharts-map-series path.highcharts-color-7.active:hover {
  fill: #1f8234 !important;
  stroke: #1f8234 !important;
}

#state-map.police-department .highcharts-map-series path {
  /* Grade Incomplete */
  /* Grade F Minus */
  /* Grade F */
  /* Grade D */
  /* Grade C */
  /* Grade B */
  /* Grade A */
}
#state-map.police-department .highcharts-map-series path.highcharts-color-1, #state-map.police-department .highcharts-map-series path.highcharts-color-2, #state-map.police-department .highcharts-map-series path.highcharts-color-3, #state-map.police-department .highcharts-map-series path.highcharts-color-4, #state-map.police-department .highcharts-map-series path.highcharts-color-5, #state-map.police-department .highcharts-map-series path.highcharts-color-6, #state-map.police-department .highcharts-map-series path.highcharts-color-7 {
  fill: #FFF;
}
#state-map.police-department .highcharts-map-series path.highcharts-color-1:hover {
  fill: #5a6f83 !important;
  stroke: #5a6f83 !important;
}
#state-map.police-department .highcharts-map-series path.highcharts-color-2:hover {
  fill: #b02424 !important;
  stroke: #b02424 !important;
}
#state-map.police-department .highcharts-map-series path.highcharts-color-3:hover {
  fill: #c14923 !important;
  stroke: #c14923 !important;
}
#state-map.police-department .highcharts-map-series path.highcharts-color-4:hover {
  fill: #9e6711 !important;
  stroke: #9e6711 !important;
}
#state-map.police-department .highcharts-map-series path.highcharts-color-5:hover {
  fill: #717b02 !important;
  stroke: #717b02 !important;
}
#state-map.police-department .highcharts-map-series path.highcharts-color-6:hover {
  fill: #537c15 !important;
  stroke: #537c15 !important;
}
#state-map.police-department .highcharts-map-series path.highcharts-color-7:hover {
  fill: #1f8234 !important;
  stroke: #1f8234 !important;
}

#static-map {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
#static-map.police-department {
  background: url("/images/maps/nationwide-police-desktop.png") center center no-repeat;
  background-size: contain;
}
#static-map.sheriff {
  background: url("/images/maps/nationwide-sheriff-desktop.png") center center no-repeat;
  background-size: contain;
}

#state-map,
#usa-map {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  overflow: visible !important;
}
#state-map.police-department,
#usa-map.police-department {
  /* Grade Incomplete */
  /* Grade F Minus */
  /* Grade F */
  /* Grade D */
  /* Grade C */
  /* Grade B */
  /* Grade A */
}
#state-map.police-department svg.highcharts-root path.highcharts-point,
#usa-map.police-department svg.highcharts-root path.highcharts-point {
  stroke: #444;
  stroke-width: 0.25px;
}
#state-map.police-department .highcharts-map-series path.highcharts-color-1,
#state-map.police-department .highcharts-map-series path.highcharts-color-2,
#state-map.police-department .highcharts-map-series path.highcharts-color-3,
#state-map.police-department .highcharts-map-series path.highcharts-color-4,
#state-map.police-department .highcharts-map-series path.highcharts-color-5,
#state-map.police-department .highcharts-map-series path.highcharts-color-6,
#state-map.police-department .highcharts-map-series path.highcharts-color-7,
#usa-map.police-department .highcharts-map-series path.highcharts-color-1,
#usa-map.police-department .highcharts-map-series path.highcharts-color-2,
#usa-map.police-department .highcharts-map-series path.highcharts-color-3,
#usa-map.police-department .highcharts-map-series path.highcharts-color-4,
#usa-map.police-department .highcharts-map-series path.highcharts-color-5,
#usa-map.police-department .highcharts-map-series path.highcharts-color-6,
#usa-map.police-department .highcharts-map-series path.highcharts-color-7 {
  fill: #f7f7f7;
}
#state-map.police-department .highcharts-halo.highcharts-color-1,
#usa-map.police-department .highcharts-halo.highcharts-color-1 {
  stroke: #5a6f83 !important;
}
#state-map.police-department .highcharts-halo.highcharts-color-2,
#usa-map.police-department .highcharts-halo.highcharts-color-2 {
  stroke: #b02424 !important;
}
#state-map.police-department .highcharts-halo.highcharts-color-3,
#usa-map.police-department .highcharts-halo.highcharts-color-3 {
  stroke: #c14923 !important;
}
#state-map.police-department .highcharts-halo.highcharts-color-4,
#usa-map.police-department .highcharts-halo.highcharts-color-4 {
  stroke: #9e6711 !important;
}
#state-map.police-department .highcharts-halo.highcharts-color-5,
#usa-map.police-department .highcharts-halo.highcharts-color-5 {
  stroke: #717b02 !important;
}
#state-map.police-department .highcharts-halo.highcharts-color-6,
#usa-map.police-department .highcharts-halo.highcharts-color-6 {
  stroke: #537c15 !important;
}
#state-map.police-department .highcharts-halo.highcharts-color-7,
#usa-map.police-department .highcharts-halo.highcharts-color-7 {
  stroke: #1f8234 !important;
}
#state-map.sheriff svg.highcharts-root path.highcharts-point,
#usa-map.sheriff svg.highcharts-root path.highcharts-point {
  stroke: #444;
  stroke-width: 0.25px;
}
#state-map.sheriff .highcharts-halo,
#usa-map.sheriff .highcharts-halo {
  display: none !important;
}

#state-map-shadow,
#usa-map-shadow {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
#state-map-shadow.police-department svg.highcharts-root path.highcharts-point,
#usa-map-shadow.police-department svg.highcharts-root path.highcharts-point {
  stroke: #AAA;
  stroke-width: 3;
}
#state-map-shadow.sheriff svg.highcharts-root path.highcharts-point,
#usa-map-shadow.sheriff svg.highcharts-root path.highcharts-point {
  stroke: #AAA;
  stroke-width: 3;
}

#state-map, #state-map-shadow {
  height: 90%;
  padding-top: 10%;
}

.highcharts-tooltip.highcharts-color-1 path.highcharts-label-box.highcharts-tooltip-box {
  fill: #5a6f83;
  stroke: white;
  stroke-opacity: 0;
  stroke-width: 0;
}
.highcharts-tooltip.highcharts-color-2 path.highcharts-label-box.highcharts-tooltip-box {
  fill: #b02424;
  stroke: white;
  stroke-opacity: 0;
  stroke-width: 0;
}
.highcharts-tooltip.highcharts-color-3 path.highcharts-label-box.highcharts-tooltip-box {
  fill: #c14923;
  stroke: white;
  stroke-opacity: 0;
  stroke-width: 0;
}
.highcharts-tooltip.highcharts-color-4 path.highcharts-label-box.highcharts-tooltip-box {
  fill: #9e6711;
  stroke: white;
  stroke-opacity: 0;
  stroke-width: 0;
}
.highcharts-tooltip.highcharts-color-5 path.highcharts-label-box.highcharts-tooltip-box {
  fill: #717b02;
  stroke: white;
  stroke-opacity: 0;
  stroke-width: 0;
}
.highcharts-tooltip.highcharts-color-6 path.highcharts-label-box.highcharts-tooltip-box {
  fill: #537c15;
  stroke: white;
  stroke-opacity: 0;
  stroke-width: 0;
}
.highcharts-tooltip.highcharts-color-7 path.highcharts-label-box.highcharts-tooltip-box {
  fill: #1f8234;
  stroke: white;
  stroke-opacity: 0;
  stroke-width: 0;
}

svg.highcharts-root .current-marker image {
  animation: 1.25s ease-in-out 0s 3 map_pulse;
}

.highcharts-halo.type-sheriff {
  opacity: 0;
  pointer-events: none;
}

#mapbox-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
}
#mapbox-wrapper #attributions {
  position: absolute;
  bottom: 10px;
  right: 15%;
  z-index: 10;
  text-align: right;
  z-index: 2;
}
#mapbox-wrapper #attributions a {
  color: #999;
  text-decoration: none;
}
#mapbox-wrapper #attributions a:hover, #mapbox-wrapper #attributions a:focus {
  color: #333;
}
#mapbox-wrapper #usa-map {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  overflow: visible !important;
}
#mapbox-wrapper #alaska-overlay {
  position: absolute;
  left: 15%;
  bottom: 5px;
  width: 120px;
  height: 80px;
  z-index: 2;
  overflow: visible !important;
}
#mapbox-wrapper #hawaii-overlay {
  position: absolute;
  left: 28%;
  bottom: 0;
  width: 100px;
  height: 70px;
  z-index: 2;
  overflow: visible !important;
}
#mapbox-wrapper .mapboxgl-ctrl-logo.mapboxgl-compact, #mapbox-wrapper .mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  display: none;
}
#mapbox-wrapper .mapboxgl-popup {
  color: white;
  font-family: "Barlow Condensed", sans-serif;
  text-transform: uppercase;
  z-index: 100;
}
#mapbox-wrapper .mapboxgl-popup .mapboxgl-popup-content {
  background-color: #464648;
}
#mapbox-wrapper .mapboxgl-popup.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: #464648;
}
#mapbox-wrapper .mapboxgl-popup.mapboxgl-popup-anchor-top .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: #464648;
}
#mapbox-wrapper .mapboxgl-popup.grade-a .mapboxgl-popup-content, #mapbox-wrapper .mapboxgl-popup.grade-a-minus .mapboxgl-popup-content, #mapbox-wrapper .mapboxgl-popup.grade-a-plus .mapboxgl-popup-content {
  background-color: #1f8234;
}
#mapbox-wrapper .mapboxgl-popup.grade-a.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-a.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-a.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-a-minus.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-a-minus.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-a-minus.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-a-plus.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-a-plus.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-a-plus.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: #1f8234;
}
#mapbox-wrapper .mapboxgl-popup.grade-a.mapboxgl-popup-anchor-top .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-a.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-a.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-a-minus.mapboxgl-popup-anchor-top .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-a-minus.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-a-minus.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-a-plus.mapboxgl-popup-anchor-top .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-a-plus.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-a-plus.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: #1f8234;
}
#mapbox-wrapper .mapboxgl-popup.grade-b .mapboxgl-popup-content, #mapbox-wrapper .mapboxgl-popup.grade-b-minus .mapboxgl-popup-content, #mapbox-wrapper .mapboxgl-popup.grade-b-plus .mapboxgl-popup-content {
  background-color: #537c15;
}
#mapbox-wrapper .mapboxgl-popup.grade-b.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-b.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-b.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-b-minus.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-b-minus.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-b-minus.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-b-plus.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-b-plus.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-b-plus.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: #537c15;
}
#mapbox-wrapper .mapboxgl-popup.grade-b.mapboxgl-popup-anchor-top .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-b.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-b.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-b-minus.mapboxgl-popup-anchor-top .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-b-minus.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-b-minus.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-b-plus.mapboxgl-popup-anchor-top .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-b-plus.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-b-plus.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: #537c15;
}
#mapbox-wrapper .mapboxgl-popup.grade-c .mapboxgl-popup-content, #mapbox-wrapper .mapboxgl-popup.grade-c-minus .mapboxgl-popup-content, #mapbox-wrapper .mapboxgl-popup.grade-c-plus .mapboxgl-popup-content {
  background-color: #717b02;
}
#mapbox-wrapper .mapboxgl-popup.grade-c.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-c.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-c.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-c-minus.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-c-minus.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-c-minus.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-c-plus.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-c-plus.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-c-plus.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: #717b02;
}
#mapbox-wrapper .mapboxgl-popup.grade-c.mapboxgl-popup-anchor-top .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-c.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-c.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-c-minus.mapboxgl-popup-anchor-top .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-c-minus.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-c-minus.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-c-plus.mapboxgl-popup-anchor-top .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-c-plus.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-c-plus.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: #717b02;
}
#mapbox-wrapper .mapboxgl-popup.grade-d .mapboxgl-popup-content, #mapbox-wrapper .mapboxgl-popup.grade-d-minus .mapboxgl-popup-content, #mapbox-wrapper .mapboxgl-popup.grade-d-plus .mapboxgl-popup-content {
  background-color: #9e6711;
}
#mapbox-wrapper .mapboxgl-popup.grade-d.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-d.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-d.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-d-minus.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-d-minus.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-d-minus.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-d-plus.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-d-plus.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-d-plus.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: #9e6711;
}
#mapbox-wrapper .mapboxgl-popup.grade-d.mapboxgl-popup-anchor-top .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-d.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-d.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-d-minus.mapboxgl-popup-anchor-top .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-d-minus.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-d-minus.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-d-plus.mapboxgl-popup-anchor-top .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-d-plus.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-d-plus.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: #9e6711;
}
#mapbox-wrapper .mapboxgl-popup.grade-f .mapboxgl-popup-content {
  background-color: #c14923;
}
#mapbox-wrapper .mapboxgl-popup.grade-f.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-f.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-f.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: #c14923;
}
#mapbox-wrapper .mapboxgl-popup.grade-f.mapboxgl-popup-anchor-top .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-f.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-f.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: #c14923;
}
#mapbox-wrapper .mapboxgl-popup.grade-f-minus .mapboxgl-popup-content {
  background-color: #b02424;
}
#mapbox-wrapper .mapboxgl-popup.grade-f-minus.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-f-minus.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-f-minus.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: #b02424;
}
#mapbox-wrapper .mapboxgl-popup.grade-f-minus.mapboxgl-popup-anchor-top .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-f-minus.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-f-minus.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: #b02424;
}
#mapbox-wrapper .mapboxgl-popup.grade-incomplete .mapboxgl-popup-content {
  background-color: #5a6f83;
}
#mapbox-wrapper .mapboxgl-popup.grade-incomplete.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-incomplete.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-incomplete.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: #5a6f83;
}
#mapbox-wrapper .mapboxgl-popup.grade-incomplete.mapboxgl-popup-anchor-top .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-incomplete.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip, #mapbox-wrapper .mapboxgl-popup.grade-incomplete.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: #5a6f83;
}
#mapbox-wrapper .mapboxgl-popup .type {
  font-size: 12px;
}
#mapbox-wrapper .mapboxgl-popup .name {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
}
#mapbox-wrapper .mapboxgl-popup .score {
  font-size: 28px;
  line-height: 28px;
  font-weight: 300;
  margin-top: 4px;
}
#mapbox-wrapper .mapboxgl-popup .incomplete {
  font-size: 24px;
  line-height: 24px;
  display: inline-block;
  opacity: 0.75;
  font-weight: 300;
  padding: 0 10px;
}
#mapbox-wrapper .mapboxgl-popup .incomplete-data {
  font-size: 12px;
  opacity: 0.75;
}

@media screen and (max-width: 940px) {
  #alaska-overlay, #hawaii-overlay {
    display: none;
  }

  #mapbox-wrapper #attributions {
    right: 0;
  }
  #mapbox-wrapper #attributions a {
    width: 20px;
    overflow: hidden;
    display: block;
    white-space: nowrap;
    transition: width 0.25s;
    font-size: 20px;
    color: #BBB;
    font-weight: 400;
  }
  #mapbox-wrapper #attributions a:hover, #mapbox-wrapper #attributions a:focus {
    width: unset !important;
    overflow: unset !important;
    font-size: 12px;
    color: #999;
  }

  .mapboxgl-ctrl-logo {
    width: 23px !important;
    overflow: hidden !important;
    transition: width 0.15s;
  }
  .mapboxgl-ctrl-logo:hover, .mapboxgl-ctrl-logo:focus {
    width: 88px !important;
    overflow: unset !important;
  }
}
#modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 500;
  display: none;
}
#modal-wrapper.open {
  display: block;
}
#modal-wrapper .modal {
  width: 300px;
  height: 300px;
  position: absolute;
  left: calc(50% - 150px);
  top: calc(50% - 150px);
  background: #FFF;
  border-radius: 8px;
  z-index: 502;
  position: absolute;
  padding: 20px;
}
#modal-wrapper .modal #modal-close {
  text-decoration: none;
  color: #464648;
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  text-align: right;
  padding: 0;
  width: 24px;
  text-align: center;
  height: 20px;
  line-height: 20px;
}
#modal-wrapper .modal #modal-label {
  color: #FFF;
  text-align: center;
  display: block;
  height: 30px;
  width: 100%;
  z-index: 5000;
  position: absolute;
  top: -30px;
  left: 0;
  line-height: 30px;
}
#modal-wrapper .modal #modal-header-tabs {
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px;
  z-index: 500;
  background: #FFF;
  border-radius: 10px;
  padding-left: 20px;
}
#modal-wrapper .modal #modal-header-tabs .show-button {
  background: #666;
  color: white;
  text-align: center;
  padding: 4px 8px;
  margin: 0;
  text-decoration: none;
  font-size: 11px;
  width: 120px;
  display: inline-block;
  border: none;
}
#modal-wrapper .modal #modal-header-tabs .show-button.active {
  background: #5a6f83;
}
#modal-wrapper .modal #modal-content {
  height: 100%;
  overflow: hidden;
}
#modal-wrapper .modal #modal-content #city-select {
  position: absolute;
  top: 36px;
  max-height: calc(100% - 50px);
  overflow: scroll;
  width: calc(100% - 40px);
}
#modal-wrapper .modal #modal-content #city-select.sheriff li.police-department {
  display: none;
}
#modal-wrapper .modal #modal-content #city-select.police-department li.sheriff {
  display: none;
}
#modal-wrapper .modal #modal-content #state-select {
  position: absolute;
  top: 20px;
  max-height: calc(100% - 40px);
  overflow: scroll;
  width: calc(100% - 40px);
  display: none;
}
#modal-wrapper .modal #modal-content #more-info-content {
  overflow: scroll;
  height: 100%;
}
#modal-wrapper .modal #modal-content ul li {
  width: 100%;
}
#modal-wrapper .modal #modal-content ul li a {
  line-height: 30px;
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  display: block;
  color: #464648;
}
#modal-wrapper .modal #modal-content ul li a.selected-city, #modal-wrapper .modal #modal-content ul li a.active {
  color: #5a6f83;
}
#modal-wrapper .modal #modal-content ul li:last-child a {
  border-bottom: none;
}
#modal-wrapper .modal #modal-content ul li.section-header {
  background: #222;
  color: white;
  text-align: center;
  padding: 8px;
  margin: 12px 0 3px;
}
#modal-wrapper .modal #modal-content ul li.section-header:first-child {
  margin-top: 0;
}
#modal-wrapper .modal #modal-content em,
#modal-wrapper .modal #modal-content i {
  font-style: italic;
}
#modal-wrapper .modal #modal-content strong,
#modal-wrapper .modal #modal-content b {
  font-style: normal;
  font-weight: bold;
}
#modal-wrapper .modal #modal-content a {
  text-decoration: none;
  color: #5a6f83;
}
#modal-wrapper.large .modal {
  height: auto;
  top: calc(50% - 240px);
}
#modal-wrapper.large .modal #modal-content {
  height: 100%;
}
#modal-wrapper #overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 501;
  background: rgba(0, 0, 0, 0.9);
  display: block;
}

#main.modal-open, #main.modal-open *, .focus-disabled {
  pointer-events: none;
}

.score-divider-full {
  width: 100%;
  height: 40px;
  position: absolute;
}
.score-divider-full::before {
  content: "";
  width: 50%;
  height: 40px;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}
.score-divider-full.grade-a::before {
  background-color: #1f8234;
}
.score-divider-full.grade-b::before {
  background-color: #537c15;
}
.score-divider-full.grade-c::before {
  background-color: #717b02;
}
.score-divider-full.grade-d::before {
  background-color: #9e6711;
}
.score-divider-full.grade-f::before {
  background-color: #c14923;
}
.score-divider-full.grade-f-minus::before {
  background-color: #b02424;
}
.score-divider-full.grade-incomplete::before {
  background-color: #5a6f83;
}

/* SECTION */
.section.bb {
  border-bottom: 1px solid #5a6f83;
}
.section.pad {
  padding-bottom: 80px;
  padding-top: 0;
}
.section sup {
  font-size: 12px;
  position: relative;
  top: -8px;
}
.section small {
  font-size: 12px;
  line-height: 14px;
  display: inline-block;
}
.section small sup {
  font-size: 10px;
  position: relative;
  top: -2px;
}
.section .view-score {
  text-align: right;
  line-height: 80px;
  vertical-align: middle;
  text-decoration: none;
}
.section .view-score span {
  vertical-align: middle;
  padding: 0 4px;
}
.section .view-score .tooltip {
  position: absolute;
  top: 53px;
  left: 20px;
}
.section .view-score .tooltip span,
.section .view-score .tooltip small {
  color: white !important;
  font-size: 11px !important;
  font-weight: 600;
}
.section.jail {
  background: #464648;
  color: #fff;
  padding: 20px 0 40px 0;
}
.section.jail p.note,
.section.jail .progress-bar.grouped span {
  color: #bbbbbb;
}
.section.jail .stat-wrapper .keys .key.key-black,
.section.jail .stat-wrapper .progress-bar-wrapper .progress-bar.key-black {
  background-color: #737373;
}
.section .content {
  max-width: 940px;
  margin: 0 auto;
}
.section .content.chart-summary {
  padding: 80px 20px;
  font-size: 20px;
  line-height: 30px;
}
.section .content.chart-summary div.left.number-list {
  padding-left: 60px;
  position: relative;
}
.section .content.chart-summary.number-list {
  padding-left: 80px;
  position: relative;
}
.section .content.chart-summary p.number-list {
  position: relative;
}
.section .content.chart-summary p.number-list::after {
  content: "";
  position: absolute;
  background: #a7cc84;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  left: -60px;
  display: block;
  top: 0;
  line-height: 40px;
  text-align: center;
  font-weight: 600;
  font-family: "Barlow Condensed", sans-serif;
  color: #FFF;
}
.section .content.chart-summary p.number-list.n1::after {
  content: "1";
}
.section .content.chart-summary p.number-list.n2::after {
  content: "2";
}
.section .content.chart-summary p.number-list.n3::after {
  content: "3";
}
.section .content.chart-summary p.number-list.n4::after {
  content: "4";
}
.section .content.chart-summary p.number-list.n5::after {
  content: "5";
}
.section .content.chart-summary p.number-list.n6::after {
  content: "6";
}
.section .content.chart-summary p.number-list.n7::after {
  content: "7";
}
.section .content.chart-summary p.number-list.n8::after {
  content: "8";
}
.section .content.chart-summary p.number-list.n9::after {
  content: "9";
}
.section .content.chart-summary p.number-list.n10::after {
  content: "10";
}
.section .content.chart-summary p.number-list.n11::after {
  content: "11";
}
.section .content.chart-summary p.number-list.n12::after {
  content: "12";
}
.section .content.chart-summary p.number-list.n13::after {
  content: "13";
}
.section .content.chart-summary p.number-list.n14::after {
  content: "15";
}
.section .content.chart-summary p.number-list.n15::after {
  content: "15";
}
.section .content.chart-summary a {
  color: #82ADD7;
  text-decoration: none;
  font-weight: 600;
}
.section .content.chart-summary blockquote {
  padding-left: 40px;
  font-style: italic;
  margin-bottom: 20px;
}
.section .content.chart-summary.no-pad-bottom {
  padding-bottom: 10px !important;
}
.section .content.chart-summary.no-pad-top {
  padding-top: 10px !important;
}
.section .content.chart-summary ul {
  list-style: disc;
  margin-left: 22px;
  margin-bottom: 16px;
  font-weight: 300;
}
.section .content.chart-summary iframe {
  background: #FFF;
  padding: 8px 16px;
  display: block;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}
.section .content.chart-summary img {
  max-width: 100%;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  padding: 10px;
}
.section .content.chart-summary p {
  margin-bottom: 20px;
  font-weight: 300;
}
.section .content.chart-summary p strong {
  font-weight: 600;
}
.section .content.chart-summary p:last-child {
  margin-bottom: 0;
}
.section .content.chart-summary h1.title {
  color: #82ADD7;
  font-family: "Barlow Condensed", sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 34px;
  margin-bottom: 20px;
  line-height: 38px;
}
.section .content.padded {
  padding: 80px 20px;
  font-size: 20px;
  line-height: 30px;
}
.section .content.padded p {
  margin-bottom: 20px;
}
.section .content.padded p:last-child {
  margin-bottom: 0;
}
.section .content.padded a.read-methodology {
  margin: 0 auto;
  display: block;
  text-align: left;
  width: 100%;
  border: 2px solid #cacaca;
  color: #fff;
  white-space: nowrap;
  font-size: 22px;
  text-decoration: none;
  padding: 0px 18px;
  font-weight: 500;
  position: relative;
  line-height: 50px;
  text-align: center;
}
.section .content.padded a.read-methodology img {
  margin: 0 auto;
  width: 100%;
  height: auto;
  max-width: 100%;
}
.section .content.text-white {
  color: #FFF;
  font-weight: 300;
}
.section .content.text-white strong {
  font-weight: 600;
}
.section .content a.results-info {
  font-size: 11px;
  background: #5a6f83;
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 50%;
  color: #FFF;
  text-align: center;
  line-height: 20px;
  vertical-align: bottom;
  margin-left: 14px;
  text-decoration: none;
  position: relative;
  top: -2px;
  text-transform: lowercase;
  font-weight: 500;
  font-family: serif;
}
.section .content a.results-info:hover {
  background-color: #5a6f83;
}
.section .content .error {
  display: block;
  font-size: 18px;
  color: #f67f85;
  text-align: center;
  padding-bottom: 18px;
}
.section .content > div.left {
  display: inline-block;
  width: 50%;
  padding-right: 20px;
  vertical-align: top;
}
.section .content > div.left.v-center {
  vertical-align: middle;
}
.section .content > div.right {
  display: inline-block;
  width: 49%;
  padding-left: 20px;
  vertical-align: top;
}
.section .content > div.right.v-center {
  vertical-align: middle;
}
.section .divider {
  opacity: 0.15;
}
.section.bg-gray {
  background-color: #464648;
}
.section.bg-gray .content.chart-summary h1.title {
  color: white;
}
.section.bg-gray .content.chart-summary a,
.section.bg-gray .content.chart-summary ul,
.section.bg-gray .content.chart-summary li {
  color: #FFF;
}
.section.bg-gray .content.chart-summary p {
  color: #FFF;
  font-weight: 300;
}
.section.bg-orange {
  background-color: #F19975;
}
.section.bg-orange p.number-list {
  position: relative;
}
.section.bg-orange p.number-list::after {
  content: "";
  background: #FFF !important;
  color: #222 !important;
}
.section.bg-orange .content.chart-summary h1.title {
  color: white;
}
.section.bg-orange .content.chart-summary a,
.section.bg-orange .content.chart-summary ul,
.section.bg-orange .content.chart-summary li {
  color: #FFF;
}
.section.bg-orange .content.chart-summary p {
  font-weight: 300;
}
.section.bg-blue {
  background-color: #82ADD7;
}
.section.bg-blue .content.chart-summary h1.title {
  color: white;
}
.section.bg-blue .content.chart-summary a,
.section.bg-blue .content.chart-summary ul,
.section.bg-blue .content.chart-summary li {
  color: #FFF;
}
.section.bg-blue .content.chart-summary p {
  font-weight: 300;
}
.section.bg-light-gray {
  background-color: #F2F4F6;
}
.section.bg-dotted {
  background: radial-gradient(rgba(0, 0, 0, 0.1) 10%, transparent 10%) 0 0, radial-gradient(rgba(0, 0, 0, 0.1) 10%, transparent 10%) 8px 8px, radial-gradient(rgba(255, 255, 252, 0.1) 10%, transparent 10%) 0 1px, radial-gradient(rgba(255, 255, 255, 0.1) 10%, transparent 5%) center center;
  background-size: 16px 16px;
  background-color: #F2F4F6;
}

.section.hero .buttons {
  text-align: left;
  margin-top: 20px;
}
.section.hero .buttons .btn {
  margin: 2px;
}
.section.hero .buttons .btn:first-child {
  margin-left: 0;
}
.section.hero .clear {
  height: 0;
  width: 100%;
  clear: both;
}
.section.hero .left {
  width: 30% !important;
  display: inline-block;
  float: left;
}
.section.hero .left .map {
  width: 283px;
  height: 310px;
  margin: 20px 0;
  display: block;
  position: relative;
}
.section.hero .right {
  width: 69% !important;
  display: inline-block;
  text-align: left;
  float: right;
}
.section.hero .right h1 {
  color: #464648;
  font-size: 24px;
  font-weight: 400;
  color: #464648;
  margin-top: 70px;
  padding-right: 25%;
  line-height: 32px;
  margin-bottom: 16px;
}
.section.hero .right h2 {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 24px;
  font-weight: 500;
  color: #464648;
  font-style: italic;
  margin-bottom: 36px;
  padding-right: 50%;
}
.section.hero .header-intro {
  text-align: center;
}
.section.hero .header-intro h1 {
  font-size: 28px;
  font-weight: 400;
  color: #464648;
  margin-top: 40px;
  padding: 18px 0;
}
.section.hero .header-intro h2 {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 28px;
  font-weight: 500;
  color: #464648;
  font-style: italic;
  margin-bottom: 60px;
}
.section.hero .header-intro.home h1 {
  margin-bottom: 20px;
}
.section.hero.sandiego {
  background: url(/images/sandiego.jpg) center center no-repeat;
  background-size: cover;
  position: relative;
}
.section.hero.sandiego:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100%;
  z-index: 10;
}
.section.hero.sandiego .content {
  text-align: center;
  padding: 120px 40px;
  z-index: 11;
  position: relative;
  max-width: 640px;
}
.section.hero.sandiego .content h1 {
  font-size: 34px;
  font-weight: 300;
  color: #FFF;
  line-height: 50px;
  padding-bottom: 15px;
}
.section.hero.sandiego .content h2 {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 34px;
  font-weight: 400;
  color: #FFF;
  font-style: italic;
}

.section.at-a-glance {
  color: #FFF;
  padding: 44px 0 20px 0;
}
.section.at-a-glance .group-header h1 {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 24px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 14px;
}
.section.at-a-glance .group-header h2 {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 14px;
}
.section.at-a-glance .group-header h2 strong {
  font-weight: 600;
}
.section.at-a-glance .group-header p {
  font-size: 15px;
  margin-bottom: 14px;
}
.section.at-a-glance .section-header {
  font-size: 16px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: bold;
  padding: 6px 0;
  text-transform: uppercase;
}
.section.at-a-glance .table-header-labels {
  position: relative;
  height: 40px;
}
.section.at-a-glance .table-header-labels.empty {
  height: 30px;
}
.section.at-a-glance .table-header-labels div {
  font-family: "Barlow Condensed", sans-serif;
  color: #FFF;
  position: absolute;
  text-transform: uppercase;
  vertical-align: bottom;
  display: inline-block;
  height: 40px;
  font-size: 14px;
}
.section.at-a-glance .table-header-labels div.worse {
  right: 120px;
  text-align: left;
  width: 40px;
  padding-top: 20px;
}
.section.at-a-glance .table-header-labels div.middle {
  right: 48px;
  text-align: center;
  width: 64px;
  padding-top: 6px;
}
.section.at-a-glance .table-header-labels div.better {
  right: 0;
  text-align: right;
  padding-top: 20px;
  width: 40px;
}
.section.at-a-glance .table-header-labels {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  position: relative;
  height: 40px;
}
.section.at-a-glance table {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
  position: relative;
}
.section.at-a-glance table tbody {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.section.at-a-glance table thead tr th {
  font-size: 16px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: bold;
  padding: 6px 0;
  text-transform: uppercase;
  color: white;
  text-align: left;
}
.section.at-a-glance table tbody tr {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  font-size: 14px;
  color: #BBB;
  vertical-align: middle;
}
.section.at-a-glance table tbody tr.double {
  line-height: 18px;
}
.section.at-a-glance table tbody tr td {
  vertical-align: middle;
}
.section.at-a-glance table tbody tr td.table-cell {
  width: 40px;
  height: 40px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.section.at-a-glance table tbody tr td.table-cell.divider {
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}
.section.at-a-glance table tbody tr.double td.table-cell {
  height: 50px;
}
.section.at-a-glance table tbody tr.key::before {
  content: "";
  position: absolute;
  font-size: 8px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
  display: block;
  font-size: 12px;
  font-weight: bold;
  margin-top: 4px;
  opacity: 1;
  transition: all 0.25s ease-in-out;
  right: calc(130px - (130px * 0));
}
.section.at-a-glance table tbody tr.key.animate-score::before {
  opacity: 0;
  right: calc(130px - (130px * 0)) !important;
}
.section.at-a-glance table tbody tr.key.double::before {
  margin-top: 8px;
}
.section.at-a-glance table tbody tr.key.percent-0::before {
  content: "0%";
  right: calc(130px - (130px * 0));
  color: #FFF;
  background-color: #b02424;
}
.section.at-a-glance table tbody tr.key.percent-1::before {
  content: "1%";
  right: calc(130px - (130px * 0.01));
  color: #FFF;
  background-color: #b02424;
}
.section.at-a-glance table tbody tr.key.percent-2::before {
  content: "2%";
  right: calc(130px - (130px * 0.02));
  color: #FFF;
  background-color: #b02424;
}
.section.at-a-glance table tbody tr.key.percent-3::before {
  content: "3%";
  right: calc(130px - (130px * 0.03));
  color: #FFF;
  background-color: #b02424;
}
.section.at-a-glance table tbody tr.key.percent-4::before {
  content: "4%";
  right: calc(130px - (130px * 0.04));
  color: #FFF;
  background-color: #b02424;
}
.section.at-a-glance table tbody tr.key.percent-5::before {
  content: "5%";
  right: calc(130px - (130px * 0.05));
  color: #FFF;
  background-color: #b02424;
}
.section.at-a-glance table tbody tr.key.percent-6::before {
  content: "6%";
  right: calc(130px - (130px * 0.06));
  color: #FFF;
  background-color: #b02424;
}
.section.at-a-glance table tbody tr.key.percent-7::before {
  content: "7%";
  right: calc(130px - (130px * 0.07));
  color: #FFF;
  background-color: #b02424;
}
.section.at-a-glance table tbody tr.key.percent-8::before {
  content: "8%";
  right: calc(130px - (130px * 0.08));
  color: #FFF;
  background-color: #b02424;
}
.section.at-a-glance table tbody tr.key.percent-9::before {
  content: "9%";
  right: calc(130px - (130px * 0.09));
  color: #FFF;
  background-color: #b02424;
}
.section.at-a-glance table tbody tr.key.percent-10::before {
  content: "10%";
  right: calc(130px - (130px * 0.10));
  color: #FFF;
  background-color: #b02424;
}
.section.at-a-glance table tbody tr.key.percent-11::before {
  content: "11%";
  right: calc(130px - (130px * 0.11));
  color: #FFF;
  background-color: #b02424;
}
.section.at-a-glance table tbody tr.key.percent-12::before {
  content: "12%";
  right: calc(130px - (130px * 0.12));
  color: #FFF;
  background-color: #b02424;
}
.section.at-a-glance table tbody tr.key.percent-13::before {
  content: "13%";
  right: calc(130px - (130px * 0.13));
  color: #FFF;
  background-color: #b02424;
}
.section.at-a-glance table tbody tr.key.percent-14::before {
  content: "14%";
  right: calc(130px - (130px * 0.14));
  color: #FFF;
  background-color: #b02424;
}
.section.at-a-glance table tbody tr.key.percent-15::before {
  content: "15%";
  right: calc(130px - (130px * 0.15));
  color: #FFF;
  background-color: #b02424;
}
.section.at-a-glance table tbody tr.key.percent-16::before {
  content: "16%";
  right: calc(130px - (130px * 0.16));
  color: #FFF;
  background-color: #b02424;
}
.section.at-a-glance table tbody tr.key.percent-17::before {
  content: "17%";
  right: calc(130px - (130px * 0.17));
  color: #FFF;
  background-color: #b02424;
}
.section.at-a-glance table tbody tr.key.percent-18::before {
  content: "18%";
  right: calc(130px - (130px * 0.18));
  color: #FFF;
  background-color: #b02424;
}
.section.at-a-glance table tbody tr.key.percent-19::before {
  content: "19%";
  right: calc(130px - (130px * 0.19));
  color: #FFF;
  background-color: #b02424;
}
.section.at-a-glance table tbody tr.key.percent-20::before {
  content: "20%";
  right: calc(130px - (130px * 0.20));
  color: #FFF;
  background-color: #b02424;
}
.section.at-a-glance table tbody tr.key.percent-21::before {
  content: "21%";
  right: calc(130px - (130px * 0.21));
  color: #FFF;
  background-color: #b02424;
}
.section.at-a-glance table tbody tr.key.percent-22::before {
  content: "22%";
  right: calc(130px - (130px * 0.22));
  color: #FFF;
  background-color: #b02424;
}
.section.at-a-glance table tbody tr.key.percent-23::before {
  content: "23%";
  right: calc(130px - (130px * 0.23));
  color: #FFF;
  background-color: #b02424;
}
.section.at-a-glance table tbody tr.key.percent-24::before {
  content: "24%";
  right: calc(130px - (130px * 0.24));
  color: #FFF;
  background-color: #b02424;
}
.section.at-a-glance table tbody tr.key.percent-25::before {
  content: "25%";
  right: calc(130px - (130px * 0.25));
  color: #FFF;
  background-color: #b02424;
}
.section.at-a-glance table tbody tr.key.percent-26::before {
  content: "26%";
  right: calc(130px - (130px * 0.26));
  color: #FFF;
  background-color: #b02424;
}
.section.at-a-glance table tbody tr.key.percent-27::before {
  content: "27%";
  right: calc(130px - (130px * 0.27));
  color: #FFF;
  background-color: #b02424;
}
.section.at-a-glance table tbody tr.key.percent-28::before {
  content: "28%";
  right: calc(130px - (130px * 0.28));
  color: #FFF;
  background-color: #b02424;
}
.section.at-a-glance table tbody tr.key.percent-29::before {
  content: "29%";
  right: calc(130px - (130px * 0.29));
  color: #FFF;
  background-color: #b02424;
}
.section.at-a-glance table tbody tr.key.percent-30::before {
  content: "30%";
  right: calc(130px - (130px * 0.30));
  color: #FFF;
  background-color: #b02424;
}
.section.at-a-glance table tbody tr.key.percent-31::before {
  content: "31%";
  right: calc(130px - (130px * 0.31));
  color: #FFF;
  background-color: #b02424;
}
.section.at-a-glance table tbody tr.key.percent-32::before {
  content: "32%";
  right: calc(130px - (130px * 0.32));
  color: #FFF;
  background-color: #b02424;
}
.section.at-a-glance table tbody tr.key.percent-33::before {
  content: "33%";
  right: calc(130px - (130px * 0.33));
  color: #FFF;
  background-color: #b02424;
}
.section.at-a-glance table tbody tr.key.percent-34::before {
  content: "34%";
  right: calc(130px - (130px * 0.34));
  color: #464648;
  background-color: #d8d8d8;
}
.section.at-a-glance table tbody tr.key.percent-35::before {
  content: "35%";
  right: calc(130px - (130px * 0.35));
  color: #464648;
  background-color: #d8d8d8;
}
.section.at-a-glance table tbody tr.key.percent-36::before {
  content: "36%";
  right: calc(130px - (130px * 0.36));
  color: #464648;
  background-color: #d8d8d8;
}
.section.at-a-glance table tbody tr.key.percent-37::before {
  content: "37%";
  right: calc(130px - (130px * 0.37));
  color: #464648;
  background-color: #d8d8d8;
}
.section.at-a-glance table tbody tr.key.percent-38::before {
  content: "38%";
  right: calc(130px - (130px * 0.38));
  color: #464648;
  background-color: #d8d8d8;
}
.section.at-a-glance table tbody tr.key.percent-39::before {
  content: "39%";
  right: calc(130px - (130px * 0.39));
  color: #464648;
  background-color: #d8d8d8;
}
.section.at-a-glance table tbody tr.key.percent-40::before {
  content: "40%";
  right: calc(130px - (130px * 0.40));
  color: #464648;
  background-color: #d8d8d8;
}
.section.at-a-glance table tbody tr.key.percent-41::before {
  content: "41%";
  right: calc(130px - (130px * 0.41));
  color: #464648;
  background-color: #d8d8d8;
}
.section.at-a-glance table tbody tr.key.percent-42::before {
  content: "42%";
  right: calc(130px - (130px * 0.42));
  color: #464648;
  background-color: #d8d8d8;
}
.section.at-a-glance table tbody tr.key.percent-43::before {
  content: "43%";
  right: calc(130px - (130px * 0.43));
  color: #464648;
  background-color: #d8d8d8;
}
.section.at-a-glance table tbody tr.key.percent-44::before {
  content: "44%";
  right: calc(130px - (130px * 0.44));
  color: #464648;
  background-color: #d8d8d8;
}
.section.at-a-glance table tbody tr.key.percent-45::before {
  content: "45%";
  right: calc(130px - (130px * 0.45));
  color: #464648;
  background-color: #d8d8d8;
}
.section.at-a-glance table tbody tr.key.percent-46::before {
  content: "46%";
  right: calc(130px - (130px * 0.46));
  color: #464648;
  background-color: #d8d8d8;
}
.section.at-a-glance table tbody tr.key.percent-47::before {
  content: "47%";
  right: calc(130px - (130px * 0.47));
  color: #464648;
  background-color: #d8d8d8;
}
.section.at-a-glance table tbody tr.key.percent-48::before {
  content: "48%";
  right: calc(130px - (130px * 0.48));
  color: #464648;
  background-color: #d8d8d8;
}
.section.at-a-glance table tbody tr.key.percent-49::before {
  content: "49%";
  right: calc(130px - (130px * 0.49));
  color: #464648;
  background-color: #d8d8d8;
}
.section.at-a-glance table tbody tr.key.percent-50::before {
  content: "50%";
  right: calc(130px - (130px * 0.50));
  color: #464648;
  background-color: #d8d8d8;
}
.section.at-a-glance table tbody tr.key.percent-51::before {
  content: "51%";
  right: calc(130px - (130px * 0.51));
  color: #464648;
  background-color: #d8d8d8;
}
.section.at-a-glance table tbody tr.key.percent-52::before {
  content: "52%";
  right: calc(130px - (130px * 0.52));
  color: #464648;
  background-color: #d8d8d8;
}
.section.at-a-glance table tbody tr.key.percent-53::before {
  content: "53%";
  right: calc(130px - (130px * 0.53));
  color: #464648;
  background-color: #d8d8d8;
}
.section.at-a-glance table tbody tr.key.percent-54::before {
  content: "54%";
  right: calc(130px - (130px * 0.54));
  color: #464648;
  background-color: #d8d8d8;
}
.section.at-a-glance table tbody tr.key.percent-55::before {
  content: "55%";
  right: calc(130px - (130px * 0.55));
  color: #464648;
  background-color: #d8d8d8;
}
.section.at-a-glance table tbody tr.key.percent-56::before {
  content: "56%";
  right: calc(130px - (130px * 0.56));
  color: #464648;
  background-color: #d8d8d8;
}
.section.at-a-glance table tbody tr.key.percent-57::before {
  content: "57%";
  right: calc(130px - (130px * 0.57));
  color: #464648;
  background-color: #d8d8d8;
}
.section.at-a-glance table tbody tr.key.percent-58::before {
  content: "58%";
  right: calc(130px - (130px * 0.58));
  color: #464648;
  background-color: #d8d8d8;
}
.section.at-a-glance table tbody tr.key.percent-59::before {
  content: "59%";
  right: calc(130px - (130px * 0.59));
  color: #464648;
  background-color: #d8d8d8;
}
.section.at-a-glance table tbody tr.key.percent-60::before {
  content: "60%";
  right: calc(130px - (130px * 0.60));
  color: #464648;
  background-color: #d8d8d8;
}
.section.at-a-glance table tbody tr.key.percent-61::before {
  content: "61%";
  right: calc(130px - (130px * 0.61));
  color: #464648;
  background-color: #d8d8d8;
}
.section.at-a-glance table tbody tr.key.percent-62::before {
  content: "62%";
  right: calc(130px - (130px * 0.62));
  color: #464648;
  background-color: #d8d8d8;
}
.section.at-a-glance table tbody tr.key.percent-63::before {
  content: "63%";
  right: calc(130px - (130px * 0.63));
  color: #464648;
  background-color: #d8d8d8;
}
.section.at-a-glance table tbody tr.key.percent-64::before {
  content: "64%";
  right: calc(130px - (130px * 0.64));
  color: #464648;
  background-color: #d8d8d8;
}
.section.at-a-glance table tbody tr.key.percent-65::before {
  content: "65%";
  right: calc(130px - (130px * 0.65));
  color: #464648;
  background-color: #d8d8d8;
}
.section.at-a-glance table tbody tr.key.percent-66::before {
  content: "66%";
  right: calc(130px - (130px * 0.66));
  color: #464648;
  background-color: #d8d8d8;
}
.section.at-a-glance table tbody tr.key.percent-67::before {
  content: "67%";
  right: calc(130px - (130px * 0.67));
  color: #464648;
  background-color: #f3f4f6;
}
.section.at-a-glance table tbody tr.key.percent-68::before {
  content: "68%";
  right: calc(130px - (130px * 0.68));
  color: #464648;
  background-color: #f3f4f6;
}
.section.at-a-glance table tbody tr.key.percent-69::before {
  content: "69%";
  right: calc(130px - (130px * 0.69));
  color: #464648;
  background-color: #f3f4f6;
}
.section.at-a-glance table tbody tr.key.percent-70::before {
  content: "70%";
  right: calc(130px - (130px * 0.70));
  color: #464648;
  background-color: #f3f4f6;
}
.section.at-a-glance table tbody tr.key.percent-71::before {
  content: "71%";
  right: calc(130px - (130px * 0.71));
  color: #464648;
  background-color: #f3f4f6;
}
.section.at-a-glance table tbody tr.key.percent-72::before {
  content: "72%";
  right: calc(130px - (130px * 0.72));
  color: #464648;
  background-color: #f3f4f6;
}
.section.at-a-glance table tbody tr.key.percent-73::before {
  content: "73%";
  right: calc(130px - (130px * 0.73));
  color: #464648;
  background-color: #f3f4f6;
}
.section.at-a-glance table tbody tr.key.percent-74::before {
  content: "74%";
  right: calc(130px - (130px * 0.74));
  color: #464648;
  background-color: #f3f4f6;
}
.section.at-a-glance table tbody tr.key.percent-75::before {
  content: "75%";
  right: calc(130px - (130px * 0.75));
  color: #464648;
  background-color: #f3f4f6;
}
.section.at-a-glance table tbody tr.key.percent-76::before {
  content: "76%";
  right: calc(130px - (130px * 0.76));
  color: #464648;
  background-color: #f3f4f6;
}
.section.at-a-glance table tbody tr.key.percent-77::before {
  content: "77%";
  right: calc(130px - (130px * 0.77));
  color: #464648;
  background-color: #f3f4f6;
}
.section.at-a-glance table tbody tr.key.percent-78::before {
  content: "78%";
  right: calc(130px - (130px * 0.78));
  color: #464648;
  background-color: #f3f4f6;
}
.section.at-a-glance table tbody tr.key.percent-79::before {
  content: "79%";
  right: calc(130px - (130px * 0.79));
  color: #464648;
  background-color: #f3f4f6;
}
.section.at-a-glance table tbody tr.key.percent-80::before {
  content: "80%";
  right: calc(130px - (130px * 0.80));
  color: #464648;
  background-color: #f3f4f6;
}
.section.at-a-glance table tbody tr.key.percent-81::before {
  content: "81%";
  right: calc(130px - (130px * 0.81));
  color: #464648;
  background-color: #f3f4f6;
}
.section.at-a-glance table tbody tr.key.percent-82::before {
  content: "82%";
  right: calc(130px - (130px * 0.82));
  color: #464648;
  background-color: #f3f4f6;
}
.section.at-a-glance table tbody tr.key.percent-83::before {
  content: "83%";
  right: calc(130px - (130px * 0.83));
  color: #464648;
  background-color: #f3f4f6;
}
.section.at-a-glance table tbody tr.key.percent-84::before {
  content: "84%";
  right: calc(130px - (130px * 0.84));
  color: #464648;
  background-color: #f3f4f6;
}
.section.at-a-glance table tbody tr.key.percent-85::before {
  content: "85%";
  right: calc(130px - (130px * 0.85));
  color: #464648;
  background-color: #f3f4f6;
}
.section.at-a-glance table tbody tr.key.percent-86::before {
  content: "86%";
  right: calc(130px - (130px * 0.86));
  color: #464648;
  background-color: #f3f4f6;
}
.section.at-a-glance table tbody tr.key.percent-87::before {
  content: "87%";
  right: calc(130px - (130px * 0.87));
  color: #464648;
  background-color: #f3f4f6;
}
.section.at-a-glance table tbody tr.key.percent-88::before {
  content: "88%";
  right: calc(130px - (130px * 0.88));
  color: #464648;
  background-color: #f3f4f6;
}
.section.at-a-glance table tbody tr.key.percent-89::before {
  content: "89%";
  right: calc(130px - (130px * 0.89));
  color: #464648;
  background-color: #f3f4f6;
}
.section.at-a-glance table tbody tr.key.percent-90::before {
  content: "90%";
  right: calc(130px - (130px * 0.90));
  color: #464648;
  background-color: #f3f4f6;
}
.section.at-a-glance table tbody tr.key.percent-91::before {
  content: "91%";
  right: calc(130px - (130px * 0.91));
  color: #464648;
  background-color: #f3f4f6;
}
.section.at-a-glance table tbody tr.key.percent-92::before {
  content: "92%";
  right: calc(130px - (130px * 0.92));
  color: #464648;
  background-color: #f3f4f6;
}
.section.at-a-glance table tbody tr.key.percent-93::before {
  content: "93%";
  right: calc(130px - (130px * 0.93));
  color: #464648;
  background-color: #f3f4f6;
}
.section.at-a-glance table tbody tr.key.percent-94::before {
  content: "94%";
  right: calc(130px - (130px * 0.94));
  color: #464648;
  background-color: #f3f4f6;
}
.section.at-a-glance table tbody tr.key.percent-95::before {
  content: "95%";
  right: calc(130px - (130px * 0.95));
  color: #464648;
  background-color: #f3f4f6;
}
.section.at-a-glance table tbody tr.key.percent-96::before {
  content: "96%";
  right: calc(130px - (130px * 0.96));
  color: #464648;
  background-color: #f3f4f6;
}
.section.at-a-glance table tbody tr.key.percent-97::before {
  content: "97%";
  right: calc(130px - (130px * 0.97));
  color: #464648;
  background-color: #f3f4f6;
}
.section.at-a-glance table tbody tr.key.percent-98::before {
  content: "98%";
  right: calc(130px - (130px * 0.98));
  color: #464648;
  background-color: #f3f4f6;
}
.section.at-a-glance table tbody tr.key.percent-99::before {
  content: "99%";
  right: calc(130px - (130px * 0.99));
  color: #464648;
  background-color: #f3f4f6;
}
.section.at-a-glance table tbody tr.key.percent-100::before {
  content: "100%";
  right: calc(130px - (130px * 1));
  color: #464648;
  background-color: #f3f4f6;
  font-size: 11px;
}
.section.at-a-glance table tbody tr.incomplete .table-label {
  background-color: #454548;
}
.section.at-a-glance table tbody tr.incomplete::before {
  content: "INCOMPLETE";
  position: absolute;
  right: 37px;
  margin-top: 6px;
  color: white;
  font-size: 11px;
  font-weight: normal;
  padding: 6px;
  background: #454548;
}
.section.at-a-glance table tbody tr.incomplete.double::before {
  margin-top: 12px;
}
.section.at-a-glance table tbody tr.no-complaints .table-label {
  background-color: #454548;
}
.section.at-a-glance table tbody tr.no-complaints::before {
  content: "NO COMPLAINTS";
  position: absolute;
  right: 29px;
  margin-top: 6px;
  color: white;
  font-size: 11px;
  font-weight: normal;
  padding: 6px;
  background: #454548;
}
.section.at-a-glance table tbody tr.no-complaints.double::before {
  margin-top: 12px;
}
.section.at-a-glance table tbody tr.none-reported .table-label {
  background-color: #454548;
}
.section.at-a-glance table tbody tr.none-reported::before {
  content: "NONE REPORTED";
  position: absolute;
  right: 37px;
  margin-top: 6px;
  color: white;
  font-size: 11px;
  font-weight: normal;
  padding: 6px;
  background: #454548;
}
.section.at-a-glance table tbody tr.none-reported.double::before {
  margin-top: 12px;
}

.section.stats .section-header {
  height: 80px;
}
.section.stats .section-header.home-page {
  height: 100px;
}
.section.stats .section-header.home-page .title {
  height: 100px;
  line-height: 120px;
}
.section.stats h1.title {
  background: transparent;
  color: white;
  height: 80px;
  line-height: 80px;
}
.section.stats h1.title::after {
  display: none;
}
.section.stats .one-third {
  width: 33%;
  display: inline-block;
  vertical-align: top;
  padding-right: 60px;
  padding-bottom: 60px;
}
.section.stats .one-third h1, .section.stats .one-third h2 {
  color: #FFF;
  font-family: "Barlow Condensed", sans-serif;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 24px;
  margin-bottom: 20px;
  line-height: 28px;
}
.section.stats .one-third h1 strong, .section.stats .one-third h2 strong {
  font-weight: 500;
}
.section.stats .one-third p {
  color: #DDD;
  font-weight: 300;
  line-height: 28px;
  font-size: 16px;
}
.section.stats .one-third p strong {
  font-weight: 500;
}
.section.stats .one-third .text {
  display: block;
}
.section.stats .one-third .chart {
  display: none;
}
.section.stats .one-third .chart #chart-mini-people-killed {
  overflow: visible !important;
}
.section.stats .one-third .chart #chart-mini-complaints-reported-label {
  position: absolute;
  width: 100%;
  display: block;
  text-align: center;
  color: #FFF;
  top: 37px;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 24px;
}
.section.stats .one-third .chart #chart-mini-complaints-reported-label .national-report {
  top: 35px;
}
.section.stats .one-third .chart .chart-mini-arrests {
  width: 100px;
  height: 100px;
  background: #58595b;
  position: relative;
  top: 30px;
}
.section.stats .one-third .chart .chart-mini-arrests:before {
  content: "Low Level Offenses";
  color: #d8d8d8;
  text-align: left;
  text-transform: uppercase;
  position: relative;
  top: -40px;
  line-height: 16px;
  font-size: 12px;
  display: block;
  width: 100px;
}
.section.stats .one-third .chart .chart-mini-arrests .filler {
  position: absolute;
  top: 0;
  left: 0;
  background: #d8d8d8;
}
.section.stats .one-third.home-page {
  padding-bottom: 0;
}
.section.stats .tableau-chart {
  padding-top: 30px;
}
.section.stats .tableau-chart .tableauPlaceholder {
  margin: 0 auto;
}
.section.stats .more-findings-wrapper {
  text-align: center;
  overflow: visible;
  height: 100px;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  padding: 0;
  align-items: center;
}
.section.stats .more-findings-wrapper .more-findings {
  background: white;
  border-radius: 4px;
  color: #464648;
  font-size: 11px;
  padding: 10px 20px;
  text-decoration: none;
  border: 0;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: bold;
  height: 30px;
}
.section.stats .more-findings-wrapper .more-findings:hover, .section.stats .more-findings-wrapper .more-findings:focus, .section.stats .more-findings-wrapper .more-findings:active {
  color: #000;
}

.section.score.grade-a {
  background: #1f8234;
}
.section.score.grade-b {
  background: #537c15;
}
.section.score.grade-c {
  background: #717b02;
}
.section.score.grade-d {
  background: #9e6711;
}
.section.score.grade-f {
  background: #c14923;
}
.section.score.grade-f-minus {
  background: #b02424;
}
.section.score.grade-incomplete {
  background: #5a6f83;
}
.section.score.animate .left {
  left: -500px;
}
.section.score.animate .right {
  right: -500px;
}
.section.score .left {
  width: 69% !important;
}
.section.score .right {
  width: 30% !important;
}
.section.score span.incomplete {
  position: absolute;
  left: -25px;
}
.section.score p.incomplete-data {
  color: white;
  font-weight: 300;
  display: block;
  padding: 30px 0 40px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  line-height: 18px;
  font-size: 16px;
}
.section.score p.incomplete-data strong {
  font-weight: 600;
}
.section.score .left {
  display: inline-block;
  padding: 30px 0;
  position: relative;
  left: 0;
  transition: left 0.5s;
}
.section.score .left .selected-location a {
  color: #FFF;
  padding: 0;
}
.section.score .left .selected-location a::after {
  color: #FFF;
}
.section.score .right {
  display: inline-block;
  font-size: 24px;
  color: #FFF;
  font-weight: 300;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  padding: 0;
  line-height: 120px;
  position: relative;
  right: 0;
  transition: right 0.5s;
}
.section.score .right .grade {
  font-size: 60px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  position: relative;
  top: -10px;
}
.section.score .right .score-label {
  color: white;
  display: inline-block;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1.5px;
  line-height: 20px;
  opacity: 0.5;
  position: absolute;
  text-decoration: none;
  text-transform: uppercase;
  bottom: 24px;
  right: 0;
}

.section.checklist {
  padding-bottom: 60px;
}
.section.checklist h1.title {
  color: #FFF;
  padding: 50px 0 30px 0;
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: 300;
}
.section.checklist h1.title span.good {
  color: #a7cc84;
}
.section.checklist h1.title span.bad {
  color: #f67f85;
}
.section.checklist .check {
  height: 48px;
  color: #FFF;
  font-size: 16px;
  padding-left: 48px;
  line-height: 18px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.section.checklist .check::before {
  content: "";
  display: block;
  background-color: #FFF;
  border-radius: 50%;
  transition: all 0.5s;
  width: 36px;
  height: 36px;
  position: absolute;
  left: 0;
  opacity: 1;
}
.section.checklist .check.animate-check::before {
  left: -30px;
  opacity: 0;
}
.section.checklist .check.checked {
  color: #a7cc84;
}
.section.checklist .check.checked::before {
  background-color: #a7cc84;
}
.section.checklist .check.checked-bad {
  color: #f67f85;
}
.section.checklist .check.checked-bad::before {
  background-color: #f67f85;
}

.section.grades {
  padding-bottom: 60px;
}
.section.grades h1.title {
  color: #5a6f83;
  padding: 50px 0 20px;
  font-size: 32px;
  line-height: 32px;
  text-transform: uppercase;
  font-weight: 500;
}
.section.grades .summary p {
  margin-bottom: 8px;
}
.section.grades .grades-header p {
  margin-bottom: 1rem;
  font-size: 1rem;
}
.section.grades .grades-header strong {
  font-weight: 600;
}
.section.grades .content > .left {
  float: left;
  padding: 0;
  padding-right: 20px;
  width: 470px;
  margin: 0;
}
.section.grades .content > .right {
  float: right;
  padding: 0;
  padding-left: 20px;
  width: 470px;
  margin: 0;
}
.section.grades .content > .clearfix {
  clear: both;
}
.section.grades .content > .clearfix:after {
  content: "";
  display: block;
  clear: both;
}
.section.grades tr.grade-row {
  position: relative;
}
.section.grades tr.grade-row a.stats-change {
  display: block;
  width: 26px;
  position: absolute;
  top: 8px;
}
.section.grades .add-new-data {
  border-top: 1px solid #979797;
  padding-top: 30px;
  margin: 0 auto;
  margin-top: 30px;
  width: calc(100% - 40px);
}
.section.grades .add-new-data .left {
  padding-left: 10%;
  width: 70%;
  padding-right: 0;
}
.section.grades .add-new-data .left strong {
  color: black;
  font-weight: 600;
}
.section.grades .add-new-data .right {
  text-align: left;
  width: 29%;
}
.section.grades .add-new-data .right a, .section.grades .add-new-data .right button {
  background: #5a6f83;
  border-radius: 4px;
  color: white;
  font-size: 11px;
  padding: 10px 20px;
  text-decoration: none;
  border: 0;
  text-transform: uppercase;
  cursor: pointer;
  position: absolute;
  overflow: visible;
}
.section.grades .filter-wrapper {
  display: none;
  /* Disabling for Now */
  margin-bottom: 8px;
  line-height: 30px;
}
.section.grades .filter-wrapper .filter-grade {
  text-decoration: none;
  color: #000;
  text-transform: uppercase;
  font-size: 12px;
  padding-right: 8px;
  transition: opacity 0.25s;
  opacity: 1;
}
.section.grades .filter-wrapper .filter-grade .percent {
  position: relative;
  top: -2px;
}
.section.grades table {
  width: 100%;
}
.section.grades table tr,
.section.grades table td {
  line-height: 32px;
}
.section.grades table tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}
.section.grades table th {
  font-family: "Barlow Condensed", sans-serif;
  text-align: left;
  font-weight: 500;
  text-transform: uppercase;
  border-bottom: 2px solid rgba(0, 0, 0, 0.35);
}
.section.grades table td {
  font-size: 16px;
  font-weight: 300;
}
.section.grades table td a {
  text-decoration: none;
  color: #000;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  position: relative;
  line-height: 38px;
  height: 38px;
  vertical-align: top;
}
.section.grades table td a .agency-name {
  display: inline-block;
  width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: top;
}
.section.grades table td a .percent {
  display: inline-block;
  font-weight: 600;
  font-size: 12px;
  padding-left: 4px;
  padding-right: 8px;
  vertical-align: top;
}
.section.grades table td a.stats-change {
  overflow: visible;
  vertical-align: top;
}
.section.grades .button {
  margin: 0 auto;
  width: 120px;
  background: #5a6f83;
  text-decoration: none;
  color: white;
  padding: 7px 0;
  border-radius: 4px;
  text-align: center;
  margin-top: 30px;
  border: 2px solid #5a6f83;
  font-size: 14px;
  line-height: 14px;
}
.section.grades .button:hover, .section.grades .button:focus, .section.grades .button:active {
  background: #5a6f83;
  border: 2px solid #5a6f83;
  color: #FFF;
}
.section.grades .button.more {
  display: none;
  background: transparent;
  color: #5a6f83;
}
.section.grades .button.more:hover, .section.grades .button.more:focus, .section.grades .button.more:active {
  background: #5a6f83;
  color: #FFF;
}
.section.grades .button.less {
  display: block;
  background: transparent;
  color: #5a6f83;
}
.section.grades .button.less:hover, .section.grades .button.less:focus, .section.grades .button.less:active {
  background: #5a6f83;
  color: #FFF;
}
.section.grades.short .left,
.section.grades.short .right {
  max-height: 360px;
  overflow: hidden;
}
.section.grades.short .button.more {
  display: block;
}
.section.grades.short .button.less {
  display: none;
}
.section.grades .grade {
  height: 20px;
  width: 20px;
  position: relative;
  top: -3px;
  border-radius: 5px;
  display: inline-block;
  vertical-align: middle;
}
.section.grades .grade.grade-a {
  background: #1f8234;
}
.section.grades .grade.grade-b {
  background: #537c15;
}
.section.grades .grade.grade-c {
  background: #717b02;
}
.section.grades .grade.grade-d {
  background: #9e6711;
}
.section.grades .grade.grade-f {
  background: #c14923;
}
.section.grades .grade.grade-f-minus {
  background: #b02424;
}
.section.grades .grade.grade-incomplete {
  background: #5a6f83;
}
.section.grades .grade-scale-key {
  line-height: 0;
  font-size: 12px !important;
  color: #333;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 30px !important;
  margin-top: 30px !important;
  text-align: center;
}
.section.grades .grade-scale-key img {
  max-width: 250px;
  display: block;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 3px;
}

.section.why {
  padding-bottom: 20px;
}
.section.why h1.title {
  color: #5a6f83;
  padding: 50px 0 20px;
  font-size: 32px;
  line-height: 32px;
  text-transform: uppercase;
  font-weight: 500;
}
.section.why .take-action {
  color: #5a6f83;
  font-size: 25px;
  line-height: 25px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: "Barlow Condensed", sans-serif;
}
.section.why ul li {
  margin-bottom: 20px;
}
.section.why ul li:last-child {
  margin-bottom: 0;
}
.section.why ul ul.contacts {
  list-style: none;
  margin-left: 0;
}
.section.why ul ul.contacts li {
  font-size: 16px;
  line-height: 24px;
}
.section.why .number ul {
  list-style: none;
  margin-left: 40px;
}
.section.why .number ul a.button {
  white-space: nowrap;
  padding: 4px 20px;
}
.section.why .number::before {
  font-family: "Barlow Condensed", sans-serif;
  position: absolute;
  font-weight: 700;
  font-size: 24px;
}
.section.why .number.number-1::before {
  content: "1.";
}
.section.why .number.number-2::before {
  content: "2.";
}
.section.why a {
  color: #5a6f83;
  text-decoration: none;
}
.section.why a.button {
  background: #5a6f83;
  text-decoration: none;
  color: white;
  padding: 6px 20px;
  border-radius: 4px;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  margin-top: 20px;
  display: inline-block;
  margin-right: 14px;
}
.section.why strong {
  font-weight: 600;
}

.section.next {
  padding-bottom: 60px;
}
.section.next h1.title {
  color: #5a6f83;
  padding: 50px 0 20px;
  font-size: 32px;
  line-height: 32px;
  text-transform: uppercase;
  font-weight: 500;
  position: relative;
}
.section.next h1.title::before {
  content: "";
  width: 100%;
  position: absolute;
  height: 4px;
  bottom: -54px;
  background: #c14923;
  background: -moz-linear-gradient(left, #c14923 0%, #d5ce71 31%, #7cc381 100%);
  background: -webkit-linear-gradient(left, #c14923 0%, #d5ce71 31%, #7cc381 100%);
  background: linear-gradient(to right, #c14923 0%, #d5ce71 31%, #7cc381 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$color-red-orange-bkgrd", endColorstr="#7cc381", GradientType=1);
}
.section.next h1.title::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background: transparent;
  border-top: 4px solid #7cc381;
  border-right: 4px solid #7cc381;
  transform: translate3d(0, -50%, 0) rotate(45deg);
  right: 0;
  bottom: -72px;
}
.section.next .step {
  width: 33%;
  display: inline-block;
  vertical-align: top;
  padding-right: 60px;
}
.section.next .step > div {
  padding-top: 40px;
}
.section.next .step img {
  height: auto;
  display: inline-block;
  float: left;
  width: 70px;
  shape-margin: 10px;
  margin-right: 20px;
  margin-bottom: 10px;
  max-height: 56px;
}
.section.next .step p {
  font-size: 14px;
  line-height: 22px;
  color: #464648;
}
.section.next .step h2 {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 20px;
  line-height: 36px;
  text-transform: uppercase;
  font-weight: 500;
}
.section.next .step strong {
  font-weight: 600;
}
.section.next .step span.completed,
.section.next .step span.in-progress {
  display: inline-block;
  color: #5a6f83;
  font-size: 20px;
  line-height: 20px;
  position: relative;
  top: 0;
  margin-left: 2px;
  font-weight: 600;
}
.section.next .step-1 h2 {
  color: #5a6f83;
}
.section.next .step-1 img {
  shape-outside: url(/images/next/step-1.svg);
}
.section.next .step-2 h2 {
  color: #5a6f83;
}
.section.next .step-2 img {
  shape-outside: url(/images/next/step-2.svg);
}
.section.next .step-3 h2 {
  color: #5a6f83;
}
.section.next .step-3 img {
  shape-outside: url(/images/next/step-3.svg);
}

.section.about {
  padding: 20px;
}
.section.about .content {
  line-height: 24px;
  overflow: visible;
}
.section.about .content img {
  max-width: 500px;
}
.section.about .content img.narrow {
  max-width: 300px;
}
.section.about .content h1,
.section.about .content h2,
.section.about .content h3 {
  text-transform: uppercase;
  margin: 14px 0;
}
.section.about .content h1.title {
  color: #5a6f83;
  padding: 0;
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
}
.section.about .content h2.title {
  color: #464648;
  padding: 0;
  font-size: 28px;
  line-height: 32px;
  font-weight: 300;
}
.section.about .content h3.title {
  color: #5a6f83;
  padding: 0;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
}
.section.about .content a {
  color: #5a6f83;
  text-decoration: none;
}
.section.about .content ol {
  list-style: decimal;
  margin: 14px 22px;
}

.section.footer {
  padding: 20px 0;
}
.section.footer a.social {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-right: 20px;
  text-align: center;
  line-height: 30px;
  color: #CCC;
  margin-top: 8px;
  font-size: 30px;
  transition: color 0.25s;
}
.section.footer a.social:hover {
  color: #FFF;
}
.section.footer .right {
  text-align: right;
}
.section.footer .right a {
  font-family: "Barlow Condensed", sans-serif;
  color: #FFF;
  text-decoration: none;
  display: inline-block;
  font-size: 22px;
  line-height: 45px;
  font-weight: 300;
  text-transform: uppercase;
  margin-right: 40px;
  padding-left: 52px;
}
.section.footer .right a.get-involved {
  background: url(/images/get-involved.svg) left center no-repeat;
}
.section.footer .right a.donate {
  background: url(/images/donate.png) left center no-repeat;
}
.section.footer .content.bt {
  border-top: 2px solid rgba(255, 255, 255, 0.25);
  margin-top: 20px;
  padding: 30px;
  text-align: center;
  color: #cacaca;
  font-weight: 300;
  font-family: "Barlow Condensed", sans-serif;
  text-transform: uppercase;
  font-size: 22px;
  color: white;
}
.section.footer .content.bt a {
  color: #FFF;
  text-decoration: none;
  display: block;
  font-size: 28px;
  letter-spacing: 2px;
  line-height: 34px;
}

.section.findings {
  padding-bottom: 60px;
}
.section.findings h1.title {
  font-family: Barlow Condensed, sans-serif;
  color: #5a6f83;
  padding: 0;
  font-size: 32px;
  line-height: 32px;
  font-weight: 500;
  margin: 40px 0 30px 0;
}
.section.findings h2.subtitle {
  font-family: Barlow Condensed, sans-serif;
  color: #5a6f83;
  padding: 0;
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
  margin: 30px 0 20px 0;
}
.section.findings strong {
  font-weight: 900;
}
.section.findings em {
  font-style: italic;
}
.section.findings p, .section.findings ol, .section.findings ul {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
}
.section.findings ol {
  margin-left: 20px;
  list-style: decimal;
}
.section.findings ul {
  margin-left: 20px;
  list-style: disc;
}
.section.findings .divider {
  height: 1px;
  width: 100%;
  display: block;
  background-color: #444;
  margin: 40px 0;
}
.section.findings p.footnote {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 16px;
}
.section.findings a {
  color: #5a6f83;
  text-decoration: underline;
  font-weight: 600;
}
.section.findings a.image-link {
  display: inline-block;
  border: 2px solid #999;
  border-radius: 6px;
  overflow: hidden;
  transition: border 0.25s;
}
.section.findings a.image-link:hover, .section.findings a.image-link:focus {
  border: 2px solid #000;
}
.section.findings a.image-link img {
  border: none;
  max-width: 100%;
  width: 500px;
}
.section.findings pre {
  padding: 30px;
  margin: 0;
  white-space: pre;
  background: #efefef;
  border-radius: 8px;
  color: cornflowerblue;
  font-size: 16px;
  font-family: Barlow Condensed, sans-serif;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  overflow: auto;
  max-width: 100%;
}
.section.findings a.back-to-top {
  width: 50px;
  height: 50px;
  font-size: 50px;
  line-height: 48px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #FFF;
  text-align: center;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  border: 0;
  transition: color 0.25s;
  display: none;
}
.section.findings a.back-to-top:hover, .section.findings a.back-to-top:active {
  color: #000;
}
.section.findings nav ul, .section.findings nav ol {
  margin-bottom: 0;
}
.section.findings nav ul a, .section.findings nav ol a {
  text-decoration: none;
  color: #000;
  transition: color 0.25s;
  font-weight: bold;
}
.section.findings nav ul a:hover, .section.findings nav ol a:hover {
  color: #5a6f83;
  text-decoration: underline;
}
.section.findings img.tableau-placeholder {
  max-width: 100%;
}
.section.findings .toc ul {
  list-style: none;
  margin: 0;
}
.section.findings .toc ul li {
  margin-bottom: 4px;
}
.section.findings .toc ul li strong {
  text-transform: uppercase;
}
.section.findings .toc ol {
  list-style-type: none;
  counter-reset: section;
  margin: 12px 0 12px 20px;
}
.section.findings .toc ol li {
  margin-bottom: 12px;
  position: relative;
  margin-left: 15px;
}
.section.findings .toc ol li:before {
  counter-increment: section;
  content: counter(section);
  position: absolute;
  left: -20px;
  margin: 0;
  padding: 0;
  font-weight: bold;
  top: 0;
}
.section.findings.toc {
  padding-top: 40px;
  margin-top: 20px;
}
.section.findings.toc h2.subtitle {
  margin-top: 0;
}

html.show-back-to-top a.back-to-top {
  display: block !important;
}

.stat-wrapper {
  margin-top: 30px;
  position: relative;
}
.stat-wrapper a {
  color: #000;
  text-decoration: none;
}
.stat-wrapper a.btn.no-data {
  background: #5a6f83;
  border-radius: 4px;
  color: white;
  font-size: 9px;
  text-decoration: none;
  border: 0;
  text-transform: uppercase;
  cursor: pointer;
  position: absolute;
  text-align: center;
  margin: 0;
  text-indent: 0;
  padding: 0 8px;
  margin-left: 6px;
}
.stat-wrapper .stats-change,
.stat-wrapper .tooltip,
.stat-wrapper .grade-arrow {
  position: relative;
  top: unset;
  left: unset;
  right: unset;
  display: inline-block;
  height: unset;
}
.stat-wrapper:last-child {
  border-bottom: none;
}
.stat-wrapper a.more-info {
  background: url(/images/see-more.png) center center no-repeat;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  background-size: cover;
}
.stat-wrapper a.more-info.visible {
  display: unset;
}
.stat-wrapper a.external-link {
  width: 32px;
  height: 32px;
  display: block;
  position: absolute;
  top: 0;
  right: -10px;
  cursor: pointer;
  display: none;
}
.stat-wrapper a.external-link::after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
  display: block;
  width: 18px;
  height: 18px;
  top: 0;
  position: absolute;
  left: 4px;
}
.stat-wrapper h3 {
  text-transform: uppercase;
  font-weight: 900;
  margin-bottom: 8px;
  font-size: 16px;
  padding-right: 22px;
}
.stat-wrapper p {
  font-size: 16px;
  line-height: 30px;
}
.stat-wrapper p.multiline {
  line-height: 18px;
  margin-bottom: 10px;
}
.stat-wrapper p.note {
  font-size: 11px;
  color: #464648;
  line-height: 18px;
  margin-bottom: 30px;
  text-align: left;
  text-indent: 5px;
}
.stat-wrapper p.good-job {
  color: #1f8234;
  text-align: center;
  padding-top: 10px;
  font-weight: 600;
}
.stat-wrapper p.good-job.pad-bottom {
  padding-bottom: 65px;
}
.stat-wrapper .minor-pad {
  padding-bottom: 18px;
}
.stat-wrapper .canvas-wrapper {
  position: relative;
}
.stat-wrapper .canvas-wrapper canvas {
  max-width: 320px;
}
.stat-wrapper .canvas-wrapper .canvas-label {
  position: absolute;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 100px;
  text-align: center;
  width: 200px;
  height: 200px;
  left: calc(50% - 100px);
  top: 90px;
  border-radius: 50%;
  font-weight: 900;
  padding-top: 50px;
  line-height: 50px;
}
.stat-wrapper .canvas-wrapper .canvas-label span {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 500;
  display: flex;
  line-height: 28px;
  margin-top: 20px;
}
.stat-wrapper .keys {
  font-size: 14px;
  white-space: nowrap;
}
.stat-wrapper .keys a {
  opacity: 1;
}
.stat-wrapper .keys a.inactive {
  opacity: 0.5;
}
.stat-wrapper .keys .key {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #999;
  margin: 0 4px 0 15px;
  line-height: 20px;
  white-space: nowrap;
}
.stat-wrapper .keys .key:first-child {
  margin-left: 0;
}
.stat-wrapper .keys .key.key-red {
  background-color: #b02424;
}
.stat-wrapper .keys .key.key-orange {
  background-color: #f19975;
}
.stat-wrapper .keys .key.key-black {
  background-color: #58595b;
}
.stat-wrapper .keys .key.key-grey {
  background-color: #9a9b9f;
}
.stat-wrapper .keys .key.key-white {
  background-color: #d4d9e4;
}
.stat-wrapper .keys .key.key-green {
  background-color: #a7cc84;
}
.stat-wrapper .progress-bar-wrapper {
  background-color: #EFEFEF;
  width: 100%;
  height: 11px;
  display: flex;
  position: relative;
  border-radius: 3px;
  margin: 14px 0 8px 0;
}
.stat-wrapper .progress-bar-wrapper .progress-bar {
  background-color: #5a6f83;
  position: absolute;
  top: 0;
  left: 0;
  width: 1%;
  display: block;
  height: 11px;
  border-radius: 3px;
  max-width: 100%;
  min-width: 1%;
  transition: width 1s;
}
.stat-wrapper .progress-bar-wrapper .progress-bar.no-data {
  min-width: 0;
}
.stat-wrapper .progress-bar-wrapper .progress-bar.grouped {
  position: relative;
  display: inline-block;
  float: left;
  border-radius: 0;
  text-align: center;
  min-width: 0;
}
.stat-wrapper .progress-bar-wrapper .progress-bar.grouped span {
  font-size: 11px;
  position: relative;
  top: -16px;
  color: #565656;
  background: white;
}
.stat-wrapper .progress-bar-wrapper .progress-bar.key-red {
  background-color: #b02424;
}
.stat-wrapper .progress-bar-wrapper .progress-bar.key-orange {
  background-color: #f19975;
}
.stat-wrapper .progress-bar-wrapper .progress-bar.key-black {
  background-color: #58595b;
}
.stat-wrapper .progress-bar-wrapper .progress-bar.key-grey {
  background-color: #9a9b9f;
}
.stat-wrapper .progress-bar-wrapper .progress-bar.key-green {
  background-color: #a7cc84;
}
.stat-wrapper .progress-bar-wrapper .progress-bar.key-blue {
  background-color: #5a6f83;
}
.stat-wrapper .progress-bar-wrapper .progress-bar.key-white {
  background-color: #d4d9e4;
}
.stat-wrapper .progress-bar-wrapper .progress-bar.bright-green {
  background-color: #b02424;
}
.stat-wrapper .progress-bar-wrapper .progress-bar.green {
  background-color: #b02424;
}
.stat-wrapper .progress-bar-wrapper .progress-bar.yellow {
  background-color: #b02424;
}
.stat-wrapper .progress-bar-wrapper .progress-bar.orange {
  background-color: #b02424;
}
.stat-wrapper .progress-bar-wrapper .progress-bar.red {
  background-color: #b02424;
}
.stat-wrapper .progress-bar-wrapper .progress-bar.always-bad {
  background-color: #b02424;
}
.stat-wrapper .progress-bar-wrapper .progress-bar.dark-grey {
  background-color: #b02424;
}
.stat-wrapper.grouped h3 {
  margin-bottom: 24px;
  display: block;
}
.stat-wrapper.grouped p {
  margin-top: 27px;
  line-height: 18px;
  margin-bottom: 0;
}

@keyframes map_pulse {
  from {
    width: 30px;
    height: 30px;
    transform: translate3d(-15px, -15px, 0);
  }
  50% {
    width: 42px;
    height: 42px;
    transform: translate3d(-21px, -21px, 0);
  }
  to {
    width: 30px;
    height: 30px;
    transform: translate3d(-15px, -15px, 0);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(3600deg);
  }
}
@keyframes bounce_select {
  0% {
    top: 12px;
  }
  50% {
    top: 4px;
  }
  100% {
    top: 12px;
  }
}
@keyframes bounce_select_mobile {
  0% {
    top: 7px;
  }
  50% {
    top: 0;
  }
  100% {
    top: 7px;
  }
}
@keyframes animate_mobile_search {
  from {
    width: calc(100% - 250px);
  }
  to {
    width: calc(100% - 160px);
  }
}
/* Responsive Imports */
/* RESPONSIVE DESKTOP */
@media screen and (min-width: 940px) {
  .left table a:focus::before {
    content: "";
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
  }

  .right table a:focus::before {
    content: "";
    width: calc(100% + 4px);
    left: -4px;
    top: 0;
    height: 100%;
  }

  .header .logo {
    left: 0;
  }
  .header #menu > ul > li > a {
    line-height: 110px;
    padding: 20px 0;
  }
  .header #menu > ul > li > a.donate {
    background: #FFF;
    color: #464648;
    padding: 8px 16px;
    border-radius: 16px;
    font-weight: 600;
  }

  .hide-desktop {
    display: none !important;
  }

  .section.hero.report .buttons {
    text-align: left;
  }

  .section.hero.home .buttons {
    text-align: center;
  }

  .section-header {
    font-size: 22px;
    position: relative;
  }
  .section-header .source-link-wrapper {
    text-align: right;
    margin-top: 12px !important;
  }
  .section-header .source-link-wrapper a {
    text-decoration: none;
  }
  .section-header .tooltip {
    position: relative !important;
    margin-left: 4px !important;
    top: 0 !important;
  }
  .section-header .results-info {
    position: absolute !important;
    right: 24px !important;
    top: 12px !important;
  }
  .section-header h1.title {
    background: white;
    color: #5a6f83;
    display: inline-block;
    font-size: 32px;
    font-weight: 500;
    height: 40px;
    line-height: 40px;
    padding-right: 60px;
    position: absolute;
    text-transform: uppercase;
    vertical-align: bottom;
    z-index: 10;
  }
  .section-header h1.title::after {
    content: "";
    width: 28px;
    height: 28px;
    background-color: white;
    transform: rotate(45deg);
    position: absolute;
    z-index: 0;
    top: 6px;
    right: -14px;
  }
  .section-header .score-divider {
    font-family: "Barlow Condensed", sans-serif;
    text-transform: uppercase;
    color: white;
    height: 40px;
    width: 100%;
    margin: 0 auto;
    line-height: 40px;
    vertical-align: top;
    position: relative;
    z-index: 2;
    text-align: right;
  }
  .section-header .score-divider::after {
    content: "";
    width: 100%;
    height: 40px;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
  }
  .section-header .score-divider .section-label {
    opacity: 0.7;
    font-size: 16px;
    line-height: 40px;
    vertical-align: top;
    padding-right: 8px;
    z-index: 30;
    position: relative;
  }
  .section-header .score-divider .section-score {
    font-size: 20px;
    line-height: 40px;
    vertical-align: top;
    z-index: 30;
    position: relative;
  }
  .section-header .score-divider .stats-change.tooltip,
.section-header .score-divider .grade-arrow span,
.section-header .score-divider .grade-arrow small {
    color: #FFF !important;
  }
  .section-header .score-divider .stats-change.tooltip {
    top: -2px !important;
    z-index: 30;
    position: relative;
  }
  .section-header .score-divider.grade-a {
    background-color: #1f8234;
  }
  .section-header .score-divider.grade-a::after {
    background-color: #1f8234;
  }
  .section-header .score-divider.grade-b {
    background-color: #537c15;
  }
  .section-header .score-divider.grade-b::after {
    background-color: #537c15;
  }
  .section-header .score-divider.grade-c {
    background-color: #717b02;
  }
  .section-header .score-divider.grade-c::after {
    background-color: #717b02;
  }
  .section-header .score-divider.grade-d {
    background-color: #9e6711;
  }
  .section-header .score-divider.grade-d::after {
    background-color: #9e6711;
  }
  .section-header .score-divider.grade-f {
    background-color: #c14923;
  }
  .section-header .score-divider.grade-f::after {
    background-color: #c14923;
  }
  .section-header .score-divider.grade-f-minus {
    background-color: #b02424;
  }
  .section-header .score-divider.grade-f-minus::after {
    background-color: #b02424;
  }
  .section-header .score-divider.grade-incomplete {
    background-color: #5a6f83;
  }
  .section-header .score-divider.grade-incomplete::after {
    background-color: #5a6f83;
  }
}
/* RESPONSIVE MOBILE */
@media screen and (max-width: 940px) {
  .hide-mobile {
    display: none !important;
  }

  .break-mobile {
    display: block;
    width: 100%;
    height: 0px;
    clear: both;
  }

  .section.at-a-glance .group-header {
    padding-left: 20px;
    padding-right: 20px;
  }
  .section.at-a-glance .group-header p {
    font-size: 13px;
  }
  .section.at-a-glance .right .table-header-labels {
    height: 30px;
  }
  .section.at-a-glance .right .table-header-labels div {
    display: none;
  }

  .section.hero.report .buttons {
    text-align: center;
  }

  .section.hero.home.pad {
    padding-top: 0;
  }
  .section.hero.home .buttons {
    text-align: center;
  }

  [role=tablist] {
    text-align: left;
  }

  [role=tab] {
    width: auto;
    white-space: nowrap;
    font-size: 16px;
    padding: 0 16px;
  }

  [role=tabpanel] .left,
[role=tabpanel] .right {
    width: 100%;
    padding: 0;
  }
  [role=tabpanel] .number ul {
    margin-left: 20px !important;
  }

  #state-map-layer,
#usa-map-layer {
    max-width: 75%;
    margin: 0 auto;
    height: 260px;
    overflow: visible;
  }

  #map-loading {
    font-size: 18px;
  }

  #usa-map-layer {
    max-width: calc(100% - 20px);
    height: 240px;
  }

  #state-selection {
    top: -4px;
    height: 60px;
    line-height: 60px;
    left: -16px;
  }
  #state-selection::after {
    right: -45px;
    top: -13px;
  }

  a.read-methodology {
    margin-top: 40px !important;
  }

  div.number-list {
    padding-left: 40px;
  }

  p.number-list::after {
    left: -50px;
  }

  .view-score .tooltip {
    left: unset !important;
    top: 9px !important;
    right: 0;
  }

  .section .content.chart-summary {
    padding: 40px 0px;
    font-size: 16px;
    line-height: 24px;
  }
  .section .content.chart-summary.padded {
    padding: 40px 20px;
  }
  .section .content.chart-summary blockquote {
    padding-left: 20px;
  }
  .section .content.chart-summary iframe,
.section .content.chart-summary img {
    margin-top: 20px;
  }
  .section .content.chart-summary div.left.number-list {
    padding-left: 60px;
    position: relative;
  }
  .section .content.chart-summary.number-list {
    padding-left: 60px;
    position: relative;
  }
  .section .content.chart-summary p.number-list::after {
    content: "";
    left: -40px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    top: 6px;
  }

  p.good-job.pad-bottom {
    padding-bottom: 0 !important;
  }

  .number.number-2 {
    margin-top: 20px;
  }

  .minor-pad {
    padding-bottom: 0;
  }

  .header {
    height: 60px;
    position: relative;
  }
  .header .content {
    height: 60px;
  }
  .header .logo {
    top: 12px;
    left: 14px;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 400;
    font-size: 18px;
    word-break: break-word;
    width: 100px;
    display: inline-block;
  }
  .header #mobile-toggle {
    width: 30px;
    height: 30px;
    display: block;
    background: url("/images/menu-toggle.svg") center center no-repeat;
    float: right;
    position: relative;
    right: 14px;
    top: 14px;
  }
  .header #menu {
    position: absolute;
    top: 60px;
    left: 0;
    background: #464648;
    z-index: 100;
    width: 100%;
    display: none;
  }
  .header #menu.open {
    display: block;
  }
  .header #menu > ul > li {
    display: block;
    margin-right: 0;
    line-height: 24px;
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  .header #menu > ul > li > a {
    color: #FFF;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 11px;
    letter-spacing: 1px;
    padding: 14px 0 14px 14px;
    transition: color 0.25s;
    width: 100%;
    display: block;
  }
  .header #menu > ul > li > a:hover {
    color: #5a6f83;
  }

  .section.pad {
    padding-bottom: 20px;
    padding-top: 60px;
  }
  .section.bb {
    border-bottom: 0;
  }
  .section.about .content img {
    max-width: 100%;
    width: auto;
    height: auto;
  }
  .section .content {
    overflow: hidden;
    /* WOO MOBILE */
  }
  .section .content.section-header {
    font-size: 22px;
    position: relative;
    text-align: center;
  }
  .section .content.section-header .source-link-wrapper {
    text-align: center;
    margin-top: 12px !important;
  }
  .section .content.section-header .source-link-wrapper a {
    text-decoration: none;
  }
  .section .content.section-header .tooltip {
    position: relative !important;
    margin-left: 4px !important;
    top: 0 !important;
  }
  .section .content.section-header .divider-line {
    height: 1px;
    width: calc(100% - 40px);
    margin: 0 auto;
    background-color: #5a6f83;
  }
  .section .content.section-header .results-info {
    position: absolute;
    right: 20px;
    top: 28px;
  }
  .section .content.section-header h1.title {
    color: #5a6f83;
    padding: 20px 0 20px;
    font-size: 32px;
    line-height: 32px;
    text-transform: uppercase;
    font-weight: 500;
  }
  .section .content.section-header h1.title.large-title {
    font-size: 24px;
  }
  .section .content.section-header .score-divider {
    font-family: "Barlow Condensed", sans-serif;
    text-transform: uppercase;
    color: white;
    height: 40px;
    width: calc(100% - 80px);
    margin: 0 auto;
    line-height: 40px;
    vertical-align: top;
    position: relative;
    z-index: 2;
  }
  .section .content.section-header .score-divider::before, .section .content.section-header .score-divider::after {
    content: "";
    width: 28px;
    height: 28px;
    background-color: #DDD;
    transform: rotate(45deg);
    position: absolute;
    z-index: 0;
    top: 6px;
  }
  .section .content.section-header .score-divider::before {
    left: -14px;
  }
  .section .content.section-header .score-divider::after {
    right: -14px;
  }
  .section .content.section-header .score-divider .section-label {
    opacity: 0.7;
    font-size: 16px;
    line-height: 40px;
    vertical-align: top;
    padding-right: 8px;
  }
  .section .content.section-header .score-divider .section-score {
    font-size: 20px;
    line-height: 40px;
    vertical-align: top;
  }
  .section .content.section-header .score-divider .stats-change.tooltip,
.section .content.section-header .score-divider .grade-arrow span,
.section .content.section-header .score-divider .grade-arrow small {
    color: #FFF !important;
  }
  .section .content.section-header .score-divider .stats-change.tooltip {
    top: -2px !important;
  }
  .section .content.section-header .score-divider.grade-a {
    background-color: #1f8234;
  }
  .section .content.section-header .score-divider.grade-a::before, .section .content.section-header .score-divider.grade-a::after {
    background-color: #1f8234;
  }
  .section .content.section-header .score-divider.grade-b {
    background-color: #537c15;
  }
  .section .content.section-header .score-divider.grade-b::before, .section .content.section-header .score-divider.grade-b::after {
    background-color: #537c15;
  }
  .section .content.section-header .score-divider.grade-c {
    background-color: #717b02;
  }
  .section .content.section-header .score-divider.grade-c::before, .section .content.section-header .score-divider.grade-c::after {
    background-color: #717b02;
  }
  .section .content.section-header .score-divider.grade-d {
    background-color: #9e6711;
  }
  .section .content.section-header .score-divider.grade-d::before, .section .content.section-header .score-divider.grade-d::after {
    background-color: #9e6711;
  }
  .section .content.section-header .score-divider.grade-f {
    background-color: #c14923;
  }
  .section .content.section-header .score-divider.grade-f::before, .section .content.section-header .score-divider.grade-f::after {
    background-color: #c14923;
  }
  .section .content.section-header .score-divider.grade-f-minus {
    background-color: #b02424;
  }
  .section .content.section-header .score-divider.grade-f-minus::before, .section .content.section-header .score-divider.grade-f-minus::after {
    background-color: #b02424;
  }
  .section .content.section-header .score-divider.grade-incomplete {
    background-color: #5a6f83;
  }
  .section .content.section-header .score-divider.grade-incomplete::before, .section .content.section-header .score-divider.grade-incomplete::after {
    background-color: #5a6f83;
  }
  .section .content .stat-wrapper {
    text-align: left;
  }
  .section .content .stat-wrapper.no-border-mobile {
    border-bottom: 0;
  }
  .section .content .stat-wrapper.grouped {
    padding-bottom: 22px;
  }
  .section .content .stat-wrapper h3 {
    font-size: 14px;
    line-height: 18px;
  }
  .section .content .stat-wrapper p {
    font-size: 14px;
    line-height: 18px;
  }
  .section .content .stat-wrapper .keys {
    white-space: nowrap;
  }
  .section .content .stat-wrapper .keys .key {
    margin: 0 1px 0 5px;
  }
  .section .content .stat-wrapper .keys .key:first-child {
    margin-left: 0;
  }
  .section .content .good-job {
    padding-bottom: 20px;
    display: block;
  }
  .section .content p.note {
    font-size: 11px;
  }
  .section .content > div.left {
    display: block;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    vertical-align: top;
  }
  .section .content > div.left.v-center {
    vertical-align: middle;
  }
  .section .content > div.right {
    display: block;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .section .content > div.right.v-center {
    vertical-align: middle;
  }

  .section.grades .content > .left {
    float: unset;
    padding: 0 20px;
    width: 100%;
  }
  .section.grades .content > .right {
    float: unset;
    padding: 0 20px;
    width: 100%;
  }
  .section.grades .content > .left table td, .section.grades .content > .right table td {
    max-width: calc(100vw - 80px);
  }

  .state-list-footer {
    display: none;
  }

  .section.stats {
    padding: 20px 0 40px 0;
  }
  .section.stats .section-header {
    height: 40px;
    padding: 0 20px;
    text-align: left;
  }
  .section.stats .section-header h1.title {
    color: white;
    padding: unset;
  }
  .section.stats .one-third {
    width: 100%;
    display: block;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0;
    margin-top: 30px;
  }
  .section.stats .one-third:first-child {
    margin-top: 0;
  }
  .section.stats .one-third h1 {
    font-size: 22px;
    margin-bottom: 10px;
    line-height: 28px;
    padding-top: 20px;
    width: 60%;
  }
  .section.stats .one-third p {
    font-size: 14px;
    line-height: 20px;
  }
  .section.stats .one-third .text {
    display: inline-flex;
    width: 60%;
    vertical-align: top;
  }
  .section.stats .one-third .chart {
    width: 100px;
    position: relative;
    float: right;
    display: block;
  }
  .section.stats .one-third .chart.chart-1 {
    top: 0;
  }
  .section.stats .one-third .chart.chart-2 {
    top: -30px;
  }
  .section.stats .one-third .chart.chart-3 {
    top: -20px;
  }
  .section.stats .one-third .chart div {
    display: block;
    margin: 0 auto;
  }

  .section.current-state {
    height: 60px;
    line-height: 60px;
    font-size: 32px;
  }
  .section.current-state svg {
    width: 32px;
    height: 32px;
  }

  .section.bg-dotted {
    background: radial-gradient(rgba(0, 0, 0, 0.1) 10%, transparent 10%) 0 0, radial-gradient(rgba(0, 0, 0, 0.1) 10%, transparent 10%) 4px 4px, radial-gradient(rgba(255, 255, 252, 0.1) 10%, transparent 10%) 0 1px, radial-gradient(rgba(255, 255, 255, 0.1) 10%, transparent 5%) center center;
    background-size: 10px 10px;
    background-color: #F2F4F6;
  }

  .section.hero .map {
    margin: 0 auto !important;
    margin-bottom: 20px !important;
  }
  .section.hero .left,
.section.hero .right {
    width: 100% !important;
    display: block;
  }
  .section.hero .right,
.section.hero .header-intro {
    padding: 0 40px;
    text-align: center;
  }
  .section.hero .right h1,
.section.hero .header-intro h1 {
    font-size: 24px;
    margin-top: 50px;
    line-height: 24px;
    font-weight: 400;
    padding: 0;
    text-align: center;
  }
  .section.hero .right h2,
.section.hero .header-intro h2 {
    font-size: 24px;
    margin-bottom: 24px;
    line-height: 28px;
    font-weight: 400;
    max-width: 80%;
    margin: 0 auto;
    margin-top: 4px;
    margin-bottom: 30px;
    padding: 0;
    text-align: center;
  }
  .section.hero .buttons {
    text-align: center;
    margin-top: 10px;
  }

  .section.score {
    position: sticky;
    top: 0;
    z-index: 500;
  }
  .section.score.grade-a {
    background: #1f8234;
  }
  .section.score.grade-b {
    background: #537c15;
  }
  .section.score.grade-c {
    background: #717b02;
  }
  .section.score.grade-d {
    background: #9e6711;
  }
  .section.score.grade-f {
    background: #c14923;
  }
  .section.score.grade-f-minus {
    background: #b02424;
  }
  .section.score.grade-incomplete {
    background: #5a6f83;
  }
  .section.score .left,
.section.score .right {
    display: inline-block !important;
    height: 80px;
  }
  .section.score .left {
    width: 69% !important;
  }
  .section.score .right {
    width: 30% !important;
  }
  .section.score p.incomplete-data {
    margin: 0 20px;
  }
  .section.score .right {
    font-size: 18px;
  }
  .section.score .right span {
    position: relative;
    top: -22px;
  }
  .section.score .right span i {
    font-weight: 300;
    font-style: normal;
  }
  .section.score .right .score-label {
    right: 16px;
    top: 50px;
    font-size: 14px;
    opacity: 0.65;
    line-height: 14px;
  }
  .section.score .right .grade {
    font-size: 36px;
    line-height: 36px;
    top: -30px;
  }

  .selected-location .selected-location-label {
    left: 21px;
    bottom: 14px;
    font-size: 14px;
    opacity: 0.65;
    line-height: 14px;
  }
  .selected-location a {
    font-size: 32px;
    padding-bottom: 20px;
    top: -14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: visible;
  }
  .selected-location a span.incomplete {
    left: -15px !important;
  }
  .selected-location a::after {
    font-size: 8px;
    right: -20px;
    top: 7px;
    animation: bounce_select_mobile 1s 3 ease-in-out;
  }
  .selected-location.long .selected-location-label {
    bottom: 20px;
  }
  .selected-location.long a {
    font-size: 26px;
  }
  .selected-location.really-long .selected-location-label {
    bottom: 10px;
  }
  .selected-location.really-long a {
    top: -16px;
    font-size: 18px;
    white-space: normal;
  }

  .section.checklist {
    padding-bottom: 30px;
  }
  .section.checklist h1.title {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 30px;
    line-height: 34px;
    padding-top: 30px;
    padding-bottom: 20px;
  }
  .section.checklist .check {
    font-size: 14px;
  }

  .section.grades {
    padding-bottom: 50px;
  }
  .section.grades .summary {
    padding: 0 20px 20px 20px;
  }
  .section.grades h1.title {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 30px;
    line-height: 34px;
    padding-top: 30px;
    padding-bottom: 20px;
  }
  .section.grades table tr.hide-mobile {
    display: none;
  }
  .section.grades.short .right:not(.add-data) {
    display: none;
  }
  .section.grades .add-new-data {
    border-top: 1px solid #979797;
    padding-top: 30px;
    margin: 0 auto;
    margin-top: 30px;
    width: calc(100% - 40px);
  }
  .section.grades .add-new-data .left,
.section.grades .add-new-data .right {
    padding: 0;
    width: 100%;
  }
  .section.grades .add-new-data .right {
    display: flex;
    justify-content: center;
  }
  .section.grades .add-new-data .right a {
    margin-top: 30px;
    position: unset !important;
    overflow: unset !important;
  }
  .section.grades .add-new-data button {
    margin-top: 30px;
    position: absolute;
    overflow: visible;
  }

  .section.why .content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .section.why h1.title {
    font-size: 30px;
    line-height: 34px;
    padding-top: 30px;
    padding-bottom: 20px;
  }
  .section.why ul.contacts li .divider {
    width: 100%;
    display: block;
    height: 0;
    clear: both;
    overflow: hidden;
  }

  .section.next .content {
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
  }
  .section.next .content::before {
    content: "";
    width: 4px;
    position: absolute;
    height: calc(100% - 65px);
    top: 0;
    background: #dc6a46;
    background: -moz-linear-gradient(top, #dc6a46 0%, #d5ce71 31%, #7cc381 100%);
    background: -webkit-linear-gradient(top, #dc6a46 0%, #d5ce71 31%, #7cc381 100%);
    background: linear-gradient(to bottom, #dc6a46 0%, #d5ce71 31%, #7cc381 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$color-red-orange", endColorstr="#7cc381", GradientType=0);
    margin-top: 60px;
  }
  .section.next .content::after {
    content: "";
    position: absolute;
    width: 14px;
    height: 14px;
    background: transparent;
    border-top: 4px solid #7cc381;
    border-right: 4px solid #7cc381;
    transform: translate3d(0, -50%, 0) rotate(130deg);
    left: 15px;
    bottom: -5px;
  }
  .section.next .step {
    width: 100%;
    padding-left: 20px;
    padding-right: 0;
    margin-bottom: 20px !important;
  }
  .section.next .step h2 {
    font-size: 22px;
    line-height: 26px;
    font-weight: 400;
  }
  .section.next .step > div {
    padding-top: 20px;
  }
  .section.next h1.title {
    font-size: 30px;
    line-height: 34px;
    padding-top: 0;
    padding-bottom: 20px;
  }
  .section.next h1.title::before {
    display: none;
  }
  .section.next h1.title::after {
    display: none;
  }
  .section.next .step {
    margin-bottom: 40px;
    position: relative;
  }

  .section.jail {
    padding: 20px 0 40px 0;
  }

  .canvas-wrapper .canvas-label span {
    font-size: 20px !important;
    line-height: 20px !important;
    margin-top: 32px !important;
  }

  .section.footer {
    padding: 0;
  }
  .section.footer .left {
    display: none !important;
  }
  .section.footer .right {
    padding-top: 14px;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
  .section.footer .right a {
    line-height: 55px;
    margin: 0 8px;
  }
  .section.footer .content.bt {
    margin-top: 10px;
  }

  .section.grades .filter-wrapper {
    padding-left: 20px;
  }

  .section.findings .content {
    padding: 0 20px;
  }
}
/* RESPONSIVE MAP */
@media screen and (min-width: 740px) {
  #usa-map-layer {
    max-width: 100%;
    height: 320px;
  }
}