header, footer {
    a:focus::before {
        content: "";
        border: 2px solid #FFF;
    }
}

[role="tablist"] {
    overflow: visible;
    background: rgba(170, 184, 197, 0.3);
    height: 40px;
    border-radius: 8px 8px 0 0;
    text-align: center;
    margin-top: 20px;
}

[role="tab"] {
    margin: 0;
    height: 40px;
    border-bottom: 4px solid transparent;
    background: transparent;
    border: none;
    width: 33%;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 500;
    color: #324354;
    font-size: 20px;
    line-height: 40px;
}

[role="tab"]:hover,
[role="tab"]:focus,
[role="tab"][aria-selected="true"] {
    border-bottom: 4px solid rgba(170, 184, 197, 0.75);
}

[role="tab"][aria-selected="true"] {
    border-bottom: 4px solid $color-md-grayblue;
    outline: 0;
    color: #324354;
}

[role="tab"][aria-selected="true"]:not(:focus):not(:hover) {
    border-bottom: 4px solid $color-md-grayblue;
}

[role="tab"]:hover,
[role="tab"]:focus,
[role="tab"]:active {
    outline: 0;
    color: black;
}

[role="tab"]:hover::before,
[role="tab"]:focus::before {
    border-bottom: 4px solid $color-md-grayblue;
}

[role="tabpanel"] {
    position: relative;
    z-index: 2;
    padding: 24px;
    border-radius: 0 0 8px 8px;
    background: $color-ltlt-gray;
    margin-bottom: 14px;
    width: 100%;

    .left,
    .right {
        width: 49%;
        display: inline-block;
        vertical-align: top;
        font-size: 14px;
    }

    .left {
        padding-right: 5%;
    }

    .right {
        padding-left: 5%;
    }

    ul,
    li,
    p,
    .number::before,
    ul.contacts li {
        font-size: 14px !important;
        line-height: 20px !important;
    }
}

[role="tabpanel"]:focus {
    box-shadow: 0 0 0.2em hsl(219, 1%, 72%);
    outline: 0;
}

a:not(.tooltip):focus {
    position: relative;
    outline: none;

    &::before {
        content: "";
        border: 2px solid $color-md-grayblue;
        position: absolute;
        top: -4px;
        width: calc(100% + 8px);
        left: -4px;
        height: calc(100% + 8px);
        border-radius: 4px;
    }
}

.btn:not(.tooltip):focus, .button:not(.tooltip):focus {
    position: relative;
    outline: none;

    &::before {
        content: "";
        border: 2px solid $color-md-grayblue;
        position: absolute;
        top: -8px;
        width: calc(100% + 16px);
        left: -8px;
        height: calc(100% + 16px);
        border-radius: 8px;
    }
}

.section.score {
    a:not(.tooltip):focus {
        &::before {
            content: "";
            border: 2px solid #FFFFFF;
            position: absolute;
            top: 12px;
            width: calc(100% + 8px);
            left: 0;
            height: calc(100% - 24px);
            border-radius: 4px;
        }
    }
}

.section.jail .stat-wrapper .progress-bar-wrapper .progress-bar.grouped span {
    background: #454548;
    color: white;
}

#modal-content a:not(.tooltip):focus::before {
    content: "";
    top: 0px;
    width: 100%;
    left: 0px;
    height: 100%;
    border-radius: 4px;
}

#score-location:focus {
    &::before {
        content: "";
        border: 2px solid #FFFFFF;
        position: absolute;
        top: -4px;
        width: calc(100% + 66px);
        left: -12px;
        height: calc(100% + 34px);
        border-radius: 8px;
    }
}

#skip-nav {
    position: absolute;
    left: -999px;
    width: 1px;
    height: 1px;
    top: auto;

    &:focus {
        color: black;
        height: auto;
        width: auto;
        position: absolute;
        background: white;
        left: 20px;
        top: 20px;
        clip: unset;
        padding: 8px 12px;
        text-decoration: none;
        border-radius: 4px;
    }
}

#state-selection:focus::before {
    content: "";
    top: -12px;
    width: calc(100% + 50px);
    left: -8px;
    height: calc(100% + 26px);
}
