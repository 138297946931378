@keyframes map_pulse {
    from {
        width: 30px;
        height: 30px;
        transform: translate3d(-15px, -15px, 0);
    }

    50% {
        width: 42px;
        height: 42px;
        transform: translate3d(-21px, -21px, 0);
    }

    to {
        width: 30px;
        height: 30px;
        transform: translate3d(-15px, -15px, 0);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(3600deg);
    }
}

@keyframes bounce_select {
    0% {
        top: 12px;
    }

    50% {
        top: 4px;
    }

    100% {
        top: 12px;
    }
}

@keyframes bounce_select_mobile {
    0% {
        top: 7px;
    }

    50% {
        top: 0;
    }

    100% {
        top: 7px;
    }
}

@keyframes animate_mobile_search {
    from {
        width: calc(100% - 250px);
    }

    to {
        width: calc(100% - 160px);
    }
}
