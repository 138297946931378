.stat-wrapper {
    // border-bottom: 2px solid rgba(0, 0, 0, 0.05);
    margin-top: 30px;
    position: relative;

    a {
        color: #000;
        text-decoration: none;
    }

    a.btn.no-data {
        background: #5a6f83;
        border-radius: 4px;
        color: white;
        font-size: 9px;
        text-decoration: none;
        border: 0;
        text-transform: uppercase;
        cursor: pointer;
        position: absolute;
        text-align: center;
        margin: 0;
        text-indent: 0;
        padding: 0 8px;
        margin-left: 6px;
    }

    .stats-change,
    .tooltip,
    .grade-arrow {
        position: relative;
        top: unset;
        left: unset;
        right: unset;
        display: inline-block;
        height: unset;
    }

    &:last-child {
        border-bottom: none;
    }

    a.more-info {
        background: url(/images/see-more.png) center center no-repeat;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 0;
        right: 0;
        display: none;
        background-size: cover;

        &.visible {
            display: unset;
        }
    }

    a.external-link {
        width: 32px;
        height: 32px;
        display: block;
        position: absolute;
        top: 0;
        right: -10px;
        cursor: pointer;
        display: none;

        &::after {
            content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
            display: block;
            width: 18px;
            height: 18px;
            top: 0;
            position: absolute;
            left: 4px;
        }
    }

    h3 {
        text-transform: uppercase;
        font-weight: 900;
        margin-bottom: 8px;
        font-size: $font-paragraph;
        padding-right: 22px;
    }

    p {
        font-size: $font-paragraph;
        line-height: 30px;

        &.multiline {
            line-height: 18px;
            margin-bottom: 10px;
        }
    }

    p.note {
        font-size: $font-default;
        color: $primary-gray;
        line-height: 18px;
        margin-bottom: 30px;
        text-align: left;
        text-indent: 5px;
    }

    p.good-job {
        color: $color-green-bkgrd;
        text-align: center;
        padding-top: 10px;
        font-weight: 600;

        &.pad-bottom {
            padding-bottom: 65px;
        }
    }

    .minor-pad {
        padding-bottom: 18px;
    }

    .canvas-wrapper {
        position: relative;

        canvas {
            max-width: 320px;
        }

        .canvas-label {
            position: absolute;
            font-family: 'Barlow Condensed', sans-serif;
            font-size: 100px;
            text-align: center;
            width: 200px;
            height: 200px;
            left: calc(50% - 100px);
            top: 90px;
            border-radius: 50%;
            font-weight: 900;
            padding-top: 50px;
            line-height: 50px;

            span {
                font-size: $font-header;
                text-transform: uppercase;
                font-weight: 500;
                display: flex;
                line-height: 28px;
                margin-top: 20px;
            }
        }
    }

    .keys {
        font-size: 14px;
        white-space: nowrap;

        a {
            opacity: 1;
        }

        a.inactive {
            opacity: 0.5;
        }

        .key {
            display: inline-block;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: #999;
            margin: 0 4px 0 15px;
            line-height: 20px;
            white-space: nowrap;

            &:first-child {
                margin-left: 0;
            }

            &.key-red {
                background-color: $grade-red;
            }

            &.key-orange {
                background-color: #f19975;
            }

            &.key-black {
                background-color: #58595b;
            }

            &.key-grey {
                background-color: #9a9b9f;
            }

            &.key-white {
                background-color: #d4d9e4;
            }

            &.key-green {
                background-color: #a7cc84;
            }
        }
    }

    .progress-bar-wrapper {
        background-color: $border-color;
        width: 100%;
        height: 11px;
        display: flex;
        position: relative;
        border-radius: 3px;
        margin: 14px 0 8px 0;

        .progress-bar {
            background-color: $primary-blue;
            position: absolute;
            top: 0;
            left: 0;
            width: 1%;
            display: block;
            height: 11px;
            border-radius: 3px;
            max-width: 100%;
            min-width: 1%;
            transition: width 1s;

            &.no-data {
                min-width: 0;
            }

            &.grouped {
                position: relative;
                display: inline-block;
                float: left;
                border-radius: 0;
                text-align: center;
                min-width: 0;

                span {
                    font-size: 11px;
                    position: relative;
                    top: -16px;
                    color: #565656;
                    background: white;
                }
            }

            &.key-red {
                background-color: $grade-red;
            }

            &.key-orange {
                background-color: #f19975;
            }

            &.key-black {
                background-color: #58595b;
            }

            &.key-grey {
                background-color: #9a9b9f;
            }

            &.key-green {
                background-color: #a7cc84;
            }

            &.key-blue {
                background-color: #5a6f83;
            }

            &.key-white {
                background-color: #d4d9e4;
            }

            &.bright-green {
                background-color: $grade-red;
            }

            &.green {
                background-color: $grade-red;
            }

            &.yellow {
                background-color: $grade-red;
            }

            &.orange {
                background-color: $grade-red;
            }

            &.red {
                background-color: $grade-red;
            }

            &.always-bad {
                background-color: $grade-red;
            }

            &.dark-grey {
                background-color: $grade-red;
            }
        }
    }

    &.grouped {
        h3 {
            margin-bottom: 24px;
            display: block;
        }

        p {
            margin-top: 27px;
            line-height: 18px;
            margin-bottom: 0;
        }
    }
}
