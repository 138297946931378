#mapbox-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;

    #attributions {
        position: absolute;
        bottom: 10px;
        right: 15%;
        z-index: 10;
        text-align: right;
        z-index: 2;

        a {
            color: #999;
            text-decoration: none;

            &:hover, &:focus {
                color: #333;
            }
        }
    }

    #usa-map {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        overflow: visible !important;
    }

    #alaska-overlay {
        position: absolute;
        left: 15%;
        bottom: 5px;
        width: 120px;
        height: 80px;
        z-index: 2;
        overflow: visible !important;
    }

    #hawaii-overlay {
        position: absolute;
        left: 28%;
        bottom: 0;
        width: 100px;
        height: 70px;
        z-index: 2;
        overflow: visible !important;
    }

    .mapboxgl-ctrl-logo.mapboxgl-compact, .mapboxgl-ctrl.mapboxgl-ctrl-attrib {
        display: none;
    }

    .mapboxgl-popup {
        color: white;
        font-family: 'Barlow Condensed', sans-serif;
        text-transform: uppercase;
        z-index: 100;

        .mapboxgl-popup-content {
            background-color: $primary-gray;
        }

        &.mapboxgl-popup-anchor-bottom, &.mapboxgl-popup-anchor-bottom-left, &.mapboxgl-popup-anchor-bottom-right {
            .mapboxgl-popup-tip {
                border-top-color: $primary-gray;
            }
        }
        &.mapboxgl-popup-anchor-top, &.mapboxgl-popup-anchor-top-left, &.mapboxgl-popup-anchor-top-right {
            .mapboxgl-popup-tip {
                border-bottom-color: $primary-gray;
            }
        }

        &.grade-a, &.grade-a-minus, &.grade-a-plus {
            .mapboxgl-popup-content {
                background-color: $grade-a;
            }

            &.mapboxgl-popup-anchor-bottom, &.mapboxgl-popup-anchor-bottom-left, &.mapboxgl-popup-anchor-bottom-right {
                .mapboxgl-popup-tip {
                    border-top-color: $grade-a;
                }
            }
            &.mapboxgl-popup-anchor-top, &.mapboxgl-popup-anchor-top-left, &.mapboxgl-popup-anchor-top-right {
                .mapboxgl-popup-tip {
                    border-bottom-color: $grade-a;
                }
            }
        }

        &.grade-b, &.grade-b-minus, &.grade-b-plus {
            .mapboxgl-popup-content {
                background-color: $grade-b;
            }

            &.mapboxgl-popup-anchor-bottom, &.mapboxgl-popup-anchor-bottom-left, &.mapboxgl-popup-anchor-bottom-right {
                .mapboxgl-popup-tip {
                    border-top-color: $grade-b;
                }
            }
            &.mapboxgl-popup-anchor-top, &.mapboxgl-popup-anchor-top-left, &.mapboxgl-popup-anchor-top-right {
                .mapboxgl-popup-tip {
                    border-bottom-color: $grade-b;
                }
            }
        }

        &.grade-c, &.grade-c-minus, &.grade-c-plus {
            .mapboxgl-popup-content {
                background-color: $grade-c;
            }

            &.mapboxgl-popup-anchor-bottom, &.mapboxgl-popup-anchor-bottom-left, &.mapboxgl-popup-anchor-bottom-right {
                .mapboxgl-popup-tip {
                    border-top-color: $grade-c;
                }
            }
            &.mapboxgl-popup-anchor-top, &.mapboxgl-popup-anchor-top-left, &.mapboxgl-popup-anchor-top-right {
                .mapboxgl-popup-tip {
                    border-bottom-color: $grade-c;
                }
            }
        }

        &.grade-d, &.grade-d-minus, &.grade-d-plus {
            .mapboxgl-popup-content {
                background-color: $grade-d;
            }

            &.mapboxgl-popup-anchor-bottom, &.mapboxgl-popup-anchor-bottom-left, &.mapboxgl-popup-anchor-bottom-right {
                .mapboxgl-popup-tip {
                    border-top-color: $grade-d;
                }
            }
            &.mapboxgl-popup-anchor-top, &.mapboxgl-popup-anchor-top-left, &.mapboxgl-popup-anchor-top-right {
                .mapboxgl-popup-tip {
                    border-bottom-color: $grade-d;
                }
            }
        }

        &.grade-f {
            .mapboxgl-popup-content {
                background-color: $grade-f;
            }

            &.mapboxgl-popup-anchor-bottom, &.mapboxgl-popup-anchor-bottom-left, &.mapboxgl-popup-anchor-bottom-right {
                .mapboxgl-popup-tip {
                    border-top-color: $grade-f;
                }
            }
            &.mapboxgl-popup-anchor-top, &.mapboxgl-popup-anchor-top-left, &.mapboxgl-popup-anchor-top-right {
                .mapboxgl-popup-tip {
                    border-bottom-color: $grade-f;
                }
            }
        }

        &.grade-f-minus {
            .mapboxgl-popup-content {
                background-color: $grade-f-minus;
            }

            &.mapboxgl-popup-anchor-bottom, &.mapboxgl-popup-anchor-bottom-left, &.mapboxgl-popup-anchor-bottom-right {
                .mapboxgl-popup-tip {
                    border-top-color: $grade-f-minus;
                }
            }
            &.mapboxgl-popup-anchor-top, &.mapboxgl-popup-anchor-top-left, &.mapboxgl-popup-anchor-top-right {
                .mapboxgl-popup-tip {
                    border-bottom-color: $grade-f-minus;
                }
            }
        }

        &.grade-incomplete {
            .mapboxgl-popup-content {
                background-color: $grade-incomplete;
            }

            &.mapboxgl-popup-anchor-bottom, &.mapboxgl-popup-anchor-bottom-left, &.mapboxgl-popup-anchor-bottom-right {
                .mapboxgl-popup-tip {
                    border-top-color: $grade-incomplete;
                }
            }
            &.mapboxgl-popup-anchor-top, &.mapboxgl-popup-anchor-top-left, &.mapboxgl-popup-anchor-top-right {
                .mapboxgl-popup-tip {
                    border-bottom-color: $grade-incomplete;
                }
            }
        }

        .type {
            font-size: 12px;
        }

        .name {
            font-weight: 600;
            font-size: 24px;
            line-height: 24px;
        }

        .score {
            font-size: 28px;
            line-height: 28px;
            font-weight: 300;
            margin-top: 4px;
        }

        .incomplete {
            font-size: 24px;
            line-height: 24px;
            display: inline-block;
            opacity: 0.75;
            font-weight: 300;
            padding: 0 10px;
        }

        .incomplete-data {
            font-size: 12px;
            opacity: 0.75;
        }
    }
}

@media screen and (max-width: $bp-max-width) {
    #alaska-overlay, #hawaii-overlay {
        display: none;
    }

    #mapbox-wrapper {
        #attributions {
            right: 0;

            a {
                width: 20px;
                overflow: hidden;
                display: block;
                white-space: nowrap;
                transition: width 0.25s;
                font-size: 20px;
                color: #BBB;
                font-weight: 400;

                &:hover, &:focus {
                    width: unset !important;
                    overflow: unset !important;
                    font-size: 12px;
                    color: #999;
                }
            }
        }
    }

    .mapboxgl-ctrl-logo {
        width: 23px !important;
        overflow: hidden !important;
        transition: width 0.15s;

        &:hover, &:focus {
            width: 88px !important;
            overflow: unset !important;
        }
    }
}
